import { externalLinkProps } from 'modules/common/lib/helpers/externalLinkProps'
import NextLink from 'next/link' // eslint-disable-line no-restricted-imports
import { FC, PropsWithChildren } from 'react'
import { UrlObject } from 'url'

interface Props extends PropsWithChildren {
  className?: string
  href: string | UrlObject
  title?: string
  onClick?: () => void
  'data-testid'?: string
}

export const Link: FC<Props> = ({
  children,
  className,
  href,
  title,
  onClick,
  'data-testid': dataTestId,
}) => {
  const url = typeof href === 'string' ? href : href.href || href.pathname || ''

  const isInternalLink = typeof href !== 'string' || /^\/\w/.test(url)

  if (isInternalLink) {
    return (
      <NextLink href={href} onClick={onClick}>
        <a className={className} title={title} data-testid={dataTestId}>
          {children}
        </a>
      </NextLink>
    )
  }

  return (
    <a
      href={url}
      className={className}
      title={title}
      data-testid={dataTestId}
      onClick={onClick}
      {...externalLinkProps}
    >
      {children}
    </a>
  )
}
