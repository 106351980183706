import Axios from 'axios'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { UseQueryOptions } from 'react-query'
import { FetchAccountsResponse } from '../types/accounts'
import { EnumBankQueryKeys } from './keys'

const fetchAccounts = async (accessToken: string) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/v1/bank/accounts`

  const { data } = await Axios.get<FetchAccountsResponse>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return data
}

export const useAccountsQuery = (
  queryOptions?: UseQueryOptions<FetchAccountsResponse, Error>
) => {
  return useQueryWithAuth<FetchAccountsResponse, Error>(
    [EnumBankQueryKeys.ACCOUNTS_LIST],
    async (context, accessToken) => {
      return fetchAccounts(accessToken)
    },
    queryOptions
  )
}
