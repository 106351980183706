export enum EnumBankQueryKeys {
  ACCOUNT_BENEFICIARY = 'ACCOUNT_BENEFICIARY',
  ACCOUNT_ADD_WIDGET = 'ACCOUNT_ADD_WIDGET',
  ACCOUNTS_LIST = 'ACCOUNTS_LIST',
  ACCOUNTS_RECORD = 'ACCOUNTS_RECORD',
  TRANSACTIONS_LIST = 'TRANSACTIONS_LIST',
  BENEFICIARIES_LIST = 'BENEFICIARIES_LIST',
  PROVIDERS_LIST = 'PROVIDER_LIST',
  MANDATES_LIST = 'MANDATES_LIST',
  TRANSACTION_INFORMATION = 'TRANSACTION_INFORMATION',
  ACCOUNT_CARDS_LIST = 'ACCOUNT_CARDS_LIST',
  ACCOUNT_CARD_LIMITS = 'ACCOUNT_CARD_LIMITS',
  ACCOUNT_LIMITS = 'ACCOUNT_LIMITS',
}
