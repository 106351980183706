export interface Transaction {
  id: string
  status: TransactionStatus
  transactionDate: string
  bookingDate: string
  counterpartyName: string
  paymentMethod: TransactionPaymentMethod
  reference: string
  amount: string
  originalAmount?: string
  currency: string
  originalCurrency?: string
  type: TransactionType
  remittanceInformation: string[]
  country?: string
  iban?: string
  bic?: string
}

export enum TransactionPaymentMethod {
  CARD = 'CARD',
  TRANSFER = 'TRANSFER',
  OTHER = 'OTHER',
}

export enum TransactionStatus {
  BOOKED = 'BOOKED',
  PENDING = 'PENDING',
  OTHER = 'OTHER',
}

export enum TransactionType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum TurnoverType {
  Sale = 'Sale',
  Services = 'Services',
  OtherServices = 'OtherServices',
  Rental = 'Rental',
  Cipav = 'Cipav',
}

enum AttachmentType {
  Receipt = 'Receipt',
}

interface TransactionAmount {
  ttc: number
  ht: number
}

export interface TransactionVAT {
  rate: number
  amount: number
}

export enum TransactionDebitCategory {
  PURCHASE = 'Achat',
  GAS = 'Carburant',
  HOUSING = 'Hébergement',
  MEAL = 'Repas',
  TAXI = 'Taxi',
  TRAVEL = 'Voyage',
  OTHER = 'Autre',
}

export type TransactionCategory = TransactionDebitCategory | TurnoverType

export interface TransactionAttachmentFile {
  url: string
  path: string
  name: string
}

interface TransactionAttachment {
  id: string
  corporateId: string
  accountId: string
  transactionId: string
  file?: TransactionAttachmentFile
  attachmentType?: AttachmentType
  isValidated: boolean
  createdAt: Date
  updatedAt: Date
}

export interface TransactionInformation {
  corporateId: string
  sortKey?: string
  id: string
  accountId: string
  vat?: TransactionVAT[]
  type: TransactionType
  amount: TransactionAmount
  date: string
  note?: string
  createdAt: string
  updatedAt: string
  transactionAttachment?: TransactionAttachment
  // Credit attributes
  isTurnover?: boolean
  turnoverType?: TurnoverType
  //Debit attributes
  isProfessional?: boolean
  debitCategory?: TransactionDebitCategory
}

export type PutTransactionInformationPayload = Pick<
  TransactionInformation,
  | 'vat'
  | 'type'
  | 'note'
  | 'date'
  | 'isTurnover'
  | 'turnoverType'
  | 'isProfessional'
  | 'debitCategory'
> & { amount: Omit<TransactionAmount, 'ht'> }

export type TransactionTypeToggleKeys = keyof Pick<
  TransactionInformation,
  'isProfessional' | 'isTurnover'
>

export enum TransactionInformationError {
  TRANSACTION_COMPLEMENTARY_INFORMATION_NOT_FOUND = 'transaction_complementary_information_not_found',
}

export interface PostTransactionAttachmentPayload {
  file: File
}

export interface PostAdvancedTransactionsExportPayload {
  date: string
  email: string
}

export interface PostTransactionAttachmentResponse {
  transactionAttachmentId: string
  category?: TransactionDebitCategory
  vat?: TransactionVAT[]
}

export interface PatchTransactionAttachmentValidationPayload {
  attachmentType?: AttachmentType
  category?: TransactionDebitCategory
  vat?: TransactionVAT[]
}

export interface LinkInvoicingDocumentToTransactionPayload {
  documentId: string
}
