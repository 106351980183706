export enum MandateStatus {
  ENABLED = 'enabled',
  WAITING = 'waiting_for_user_validation',
  DISABLED = 'disabled',
  CLOSED = 'closed',
  OTHER = 'other',
}

export interface Mandate {
  mandateType: string
  mandateId: string
  creditorId: string
  creditorName: string
  fundingAccountCode: string
  lastCollectionDate?: string
  active: boolean
  blockedBy?: string
  status: MandateStatus
}

export interface FetchMandatesResponse {
  data: Mandate[]
}

export enum MandateStatusTabs {
  WAITING = 'waiting',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface MandatesByTabs {
  [MandateStatusTabs.ACTIVE]: Mandate[]
  [MandateStatusTabs.INACTIVE]: Mandate[]
  [MandateStatusTabs.WAITING]: Mandate[]
}
