import { User } from 'auth0'
import { AxiosError } from 'axios'

export interface Auth0Error {
  errorCode: 'auth0_idp_error'
  error: string
  message: string
  statusCode: number
  mfa_token?: string
}

export enum LOGIN_ERRORS {
  INVALID_GRANT = 'invalid_grant',
  TOO_MANY_ATTEMPTS = 'too_many_attempts',
}

export type Auth0User = User<
  {
    corporateId?: string
  },
  {
    firstname: string
    lastname: string
  }
>
export interface AuthenticatedUser {
  firstname?: string
  lastname?: string
  corporateId?: string
  email?: string
  emailVerified?: boolean
  phoneNumber?: string
  phoneNumberPending?: string
  phoneNumberVerified?: boolean
  hasMFA?: boolean
  sub?: string
  roles?: string[]
  signUpInformation?: string[]
  hasCompletedSca?: boolean
  isEnrolled?: boolean
  actionId?: string
}

export interface TokenUser {
  iss?: string
  sub?: string
  aud?: string[]
  iat?: number
  exp?: number
  azp?: string
  scope?: string
  gty?: string[]
  permission?: string[]
  'https://blank.app/firstname'?: string
  'https://blank.app/lastname'?: string
  'https://blank.app/email'?: string
  'https://blank.app/email_verified'?: boolean
  'https://blank.app/corporateId'?: string
  'https://blank.app/phoneNumber'?: string
  'https://blank.app/phoneNumberVerified'?: boolean
  'https://blank.app/phoneNumberPending'?: string
  'https://blank.app/hasMFA'?: boolean
  'https://blank.app/roles'?: string[]
  'https://blank.app/adminFlags'?: string[]
  'https://blank.app/hasCompletedSCA'?: boolean
  'https://blank.app/isEnrolled'?: boolean
  'https://blank.app/actionId'?: string
}
export interface Authenticator {
  id: string
  authenticator_type: string
  active: boolean
  oob_channel?: string
  name?: string
}
export interface Challenge {
  challenge_type: string
  oob_code: string
  binding_method: string
}
export interface CodeValidationResponse {
  access_token: string
  scope: string
  expires_in: number
  token_type: string
}

export const DEVICE_NAME = 'Navigateur Web'

export const DEFAULT_SCOPE = `openid profile email offline_access deviceName:${encodeURI(
  DEVICE_NAME
)}`

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).response !== undefined
}
