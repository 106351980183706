import { ScaAction } from 'modules/auth/types/sca'

export enum TransferError {
  BLOCKED = 'outbound_payment_blocked',
  INSUFFICIENT_BALANCE = 'outbound_payment_blocked_insufficient_balance',
  INVALID_IBAN_OR_BIC = 'outbound_payment_invalid_iban_or_bic',
}

export interface TransferBeneficiary {
  label: string | undefined
  value: string
  iban: string
}
export interface NewTransferFormProps {
  amount: number
  title?: string
  beneficiary?: TransferBeneficiary
}

interface PostPaymentRequestData {
  paymentTypeInformation: {
    serviceLevel: 'SEPA'
    localInstrument: 'INST'
    categoryPurpose: 'DVPM'
  }
  creditTransferTransaction: {
    requestedExecutionDate: string
    instructedAmount: {
      currency: 'EUR'
      amount: string
    }
    transactionInformation: string
  }
}

export type PostPaymentRequestPayload =
  | { actionId: string }
  | PostPaymentRequestData

export interface PostPaymentRequestResponse {
  scaAction?: ScaAction
  request?: PostPaymentRequestData
}
