import { Spinner } from 'modules/common/components/_ui/Spinner/Spinner'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const FullPageLoader = () => {
  const { t } = useTranslation('common')

  return (
    <div className="absolute top-0 left-0 flex min-h-screen w-full flex-col items-center justify-center space-y-2">
      <h1>{t('loadingInProgress')}</h1>
      <Spinner />
    </div>
  )
}
