import { LocalStorageKeys } from '_constants'

const store = (storageKey: LocalStorageKeys, data: any) => {
  if (process.env.NEXT_IS_SERVER) {
    return
  }
  window.localStorage.setItem(storageKey, JSON.stringify(data))
}

function retrieve<TReturn>(storageKey: LocalStorageKeys): TReturn | undefined {
  if (process.env.NEXT_IS_SERVER) {
    return
  }
  const data = window.localStorage.getItem(storageKey)
  return data ? JSON.parse(data) : undefined
}

const clear = (storageKey: LocalStorageKeys) => {
  if (process.env.NEXT_IS_SERVER) {
    return
  }
  window.localStorage.removeItem(storageKey)
}

const clearAll = () => window.localStorage.clear()

const localStorage = { store, retrieve, clear, clearAll }
export default localStorage
