import { useCallback } from 'react'
import { useAccountQuery } from '../../queries/useAccount.query'
import { useAccountsQuery } from '../../queries/useAccounts.query'
import { EnumAccountSource } from '../../types'

export const useInternalAccount = () => {
  const {
    data,
    isLoading: isAccountsLoading,
    isError: isAccountsError,
    refetch: refetchAccounts,
    isSuccess: isAccountsSuccess,
  } = useAccountsQuery()

  const internalAccount = data?.data.find(
    (account) => account.source === EnumAccountSource.INTERNAL
  )

  const {
    data: account,
    isError: isInternalAccountError,
    isLoading: isInternalAccountLoading,
    refetch: refetchInternalAccount,
    isSuccess: isInternalAccountSuccess,
  } = useAccountQuery(internalAccount?.id, {
    enabled: !!internalAccount,
  })

  const errorRefetch = useCallback(() => {
    if (isAccountsError) {
      refetchAccounts()
    } else if (isInternalAccountError) {
      refetchInternalAccount()
    }
  }, [
    isAccountsError,
    isInternalAccountError,
    refetchAccounts,
    refetchInternalAccount,
  ])

  return {
    errorRefetch,
    isLoading: isInternalAccountLoading || isAccountsLoading,
    isError: isInternalAccountError || isAccountsError,
    isSuccess:
      isAccountsSuccess &&
      ((!!internalAccount && isInternalAccountSuccess) || !internalAccount),
    data: account,
  }
}
