import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react'
import { Menu } from '../../types/sidebar'
import { SidebarActionsContextProvider } from './SidebarActionsContext'

export interface SidebarContextProps {
  menus: Menu[]
  selectedMenuId?: string
  selectedSubMenuId?: string
  isSidebarOpen: boolean
}

type SidebarContextStateProps = Omit<SidebarContextProps, 'menus'>

export interface SidebarContextReducerAction {
  type: 'set'
  payload: Partial<SidebarContextStateProps>
}

const defaultContext: SidebarContextProps = {
  menus: [],
  isSidebarOpen: true,
}

const SidebarContext = createContext<SidebarContextProps>(defaultContext)

function SidebarContextReducer(
  state: SidebarContextStateProps,
  action: SidebarContextReducerAction
): SidebarContextStateProps {
  switch (action.type) {
    case 'set': {
      return { ...state, ...action.payload }
    }
  }
}

const SidebarContextProvider: React.FC<
  PropsWithChildren<{ menus: Menu[] }>
> = ({ children, menus }) => {
  const [state, dispatch] = useReducer(SidebarContextReducer, {
    selectedMenuId: menus[0].id,
    selectedSubMenuId: menus[0]?.subMenus?.[0].id,
    isSidebarOpen: true,
  })

  return (
    <SidebarContext.Provider value={{ ...state, menus }}>
      <SidebarActionsContextProvider dispatch={dispatch}>
        {children}
      </SidebarActionsContextProvider>
    </SidebarContext.Provider>
  )
}

function useSidebarContextState() {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error(
      'useSidebarContextState must be used within a SidebarContextProvider'
    )
  }
  return context
}

export { SidebarContextProvider, useSidebarContextState }
