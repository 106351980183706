import { ONBOARDING_TRACKING_EVENTS } from './../../../onboarding/lib/tracking/events'
import { AUTH_TRACKING_EVENTS } from 'modules/auth/lib/tracking/events'
import { BANK_TRACKING_EVENTS } from 'modules/bank/lib/tracking/events'
import { CORPORATE_TRACKING_EVENTS } from 'modules/corporate/lib/tracking/events'
import { INVOICING_TRACKING_EVENTS } from 'modules/invoicing/lib/tracking/events'
import { SERVICES_TRACKING_EVENTS } from 'modules/services/lib/tracking/events'
import { HOME_TRACKING_EVENTS } from 'modules/home/lib/tracking/events'

export enum COMMON_TRACKING_EVENTS {
  PAGEVIEW = 'Pageview',
}
export type TRACKING_EVENTS =
  | COMMON_TRACKING_EVENTS
  | AUTH_TRACKING_EVENTS
  | INVOICING_TRACKING_EVENTS
  | BANK_TRACKING_EVENTS
  | CORPORATE_TRACKING_EVENTS
  | SERVICES_TRACKING_EVENTS
  | ONBOARDING_TRACKING_EVENTS
  | HOME_TRACKING_EVENTS
