import clsx from 'clsx'
import useSupportModal from 'modules/common/modals/useSupportModal/useSupportModal'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import { Seo } from '../Seo/Seo'
import { IconReload } from '../_icons/icons'
import { Button } from '../_ui/Button/Button'

interface Props {
  onReload?: () => void
  className?: string
  title?: string
  firstDescription?: string
  secondDescription?: string
}

export const ErrorReload: React.FC<Props> = ({
  onReload,
  className,
  title,
  firstDescription,
  secondDescription,
}) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { showModal: showSupportModal } = useSupportModal()

  return (
    <div
      className={clsx(
        'flex h-full w-full items-center justify-center',
        className
      )}
      data-testid="error-reload"
    >
      <Seo title={t('meta.title.error')} />
      <div className="flex max-w-2xl flex-col items-center justify-center">
        <Image src="/assets/img/red-cross.png" width="52" height="52" />
        <h3 className="my-4 text-center font-headings text-2xl font-bold text-info-600">
          {title || t('errors.reloadContent.title')}
        </h3>
        <div className="mb-8 space-y-8 text-center text-lg text-info-400">
          <p>
            {firstDescription || t('errors.reloadContent.firstDescription')}
          </p>
          <p>
            {secondDescription || t('errors.reloadContent.secondDescription')}
          </p>
        </div>
        <div className="flex space-x-4">
          <Button
            variant="outline"
            color="active"
            type="button"
            onClick={showSupportModal}
          >
            {t('errors.reloadContent.cta.support')}
          </Button>
          <Button
            className="flex flex-row items-center justify-center"
            variant="contained"
            color="active"
            onClick={onReload || router?.reload}
            icon={<IconReload className="h-6 w-6" />}
          >
            <span>{t('errors.reloadContent.cta.reload')}</span>
          </Button>
        </div>
      </div>
    </div>
  )
}
