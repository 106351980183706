import { Offer as BillingOffer } from 'modules/billing/types/offers'

export enum EnumOfferServiceId {
  check = 'check',
  cardFraud = 'cardFraud',
  materialGuarantee = 'materialGuarantee',
  manufacturerWarranty = 'manufacturerWarranty',
  travelAbroadOtherGuarantees = 'travelAbroadOtherGuarantees',
  travelAbroadHealthGuarantees = 'travelAbroadHealthGuarantees',
  hospitalizationGuarantee = 'hospitalizationGuarantee',
  administrativeAssistance = 'administrativeAssistance',
  whiteCard = 'whiteCard',
  blackCard = 'blackCard',
  emailSupport = 'emailSupport',
  instantMessaging = 'instantMessaging',
  phoneSupport = 'phoneSupport',
  FAQ = 'FAQ',
  accounting = 'accounting',
  urssaf = 'urssaf',
  professionalLiability = 'professionalLiability',
  healthcare = 'healthcare',
  multiriskInsurance = 'multiriskInsurance',
  providentInsurance = 'providentInsurance',
  tenYearGuarantee = 'tenYearGuarantee',
  factoring = 'factoring',
  credit = 'credit',
  paymentTerminal = 'paymentTerminal',
  otherServicesLCL = 'otherServicesLCL',
  financing = 'financing',
  priceBrochure = 'priceBrochure',
  websiteCreation = 'websiteCreation',
  digitalVisibility = 'digitalVisibility',
}

export enum EnumOfferCardType {
  BLACK_CARD = 'VISA_BUSINESS_DEBIT_NOIRE',
  WHITE_CARD = 'VISA_BUSINESS_DEBIT_BLANCHE',
}

export enum EnumOfferServiceCategory {
  FEATURE = 'FEATURE',
  TRANSACTION = 'TRANSACTION',
  IRREGULAR_OPERATION = 'IRREGULAR_OPERATION',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_CARD = 'BANK_CARD',
  BLANK_SUPPORT = 'BLANK_SUPPORT',
  PARTNER_SUPPORT = 'PARTNER_SUPPORT',
  PARTNER_INSURANCE = 'PARTNER_INSURANCE',
  PARTNER_OPERATION = 'PARTNER_OPERATION',
  INSURRANCE_ASSISTANCE = 'INSURRANCE_ASSISTANCE',
  FINANCING_PAYMENT = 'FINANCING_PAYMENT',
  ADMINISTRATIVE_ACCOUNTING = 'ADMINISTRATIVE_ACCOUNTING',
  DIGITAL_PRESENCE = 'DIGITAL_PRESENCE',
}

export enum EnumOfferServiceType {
  BANKING = 'BANKING',
  SUPPORT_ASSISTANCE = 'SUPPORT_ASSISTANCE',
  INSURANCE_GUARANTEE = 'INSURANCE_GUARANTEE',
  MANAGEMENT = 'MANAGEMENT',
  SERVICE = 'SERVICE',
}

export type OfferPlanFee = {
  monthly: {
    withoutVAT: number
    withVAT: number
  }
}

export type OfferService = {
  id: EnumOfferServiceId
  reference: string
  type: EnumOfferServiceType
  category: EnumOfferServiceCategory
  recurrence: boolean
  variablePrice: number | null
  fixedPrice: number | null
  VATRate: number
  termsOfUse?: {
    name?: string
    url?: string
  }
  email?: string
  phone?: string
  redirectionUrl?: string
  updatedAt?: string | null
}

export type CorporateOffer = BillingOffer & {
  services?: OfferService[]
}
