import { buildTimeConfig } from 'config'
import { useFeatureFlippingContext } from '../contexts/FeatureFlippingContext'
import { EnumFeatureFlipping } from '../types/featureFlipping'

export const useFeatureFlipping = () => {
  const { features, enableAll } = useFeatureFlippingContext()

  const isFeatureEnabled = (featureName: EnumFeatureFlipping) => {
    return enableAll || features?.includes(featureName)
  }

  return {
    canAccessSca:
      isFeatureEnabled(EnumFeatureFlipping.SCA) &&
      buildTimeConfig.isScaEnabledForBrand,
    canAccessManagementToolsOnboarding: isFeatureEnabled(
      EnumFeatureFlipping.MANAGEMENT_TOOLS_ONBOARDING
    ),
    allFeaturesEnabled: enableAll,
  }
}
