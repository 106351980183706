import localStorage from 'modules/common/lib/storage/localStorage'
import { RecursivePartial } from 'modules/common/types/utils'
import { DocumentFormProps } from 'modules/invoicing/components/documents/creation/DocumentCreationFields/types'
import { EnumDocumentType } from 'modules/invoicing/types/document'
import { LocalStorageKeys } from '_constants'

export const storeDocumentCreationForm = (
  documentType: EnumDocumentType,
  values: RecursivePartial<DocumentFormProps>
) => {
  if (documentType === EnumDocumentType.ESTIMATE) {
    localStorage.store(LocalStorageKeys.ESTIMATE_CREATION_FORM, values)
  } else if (documentType === EnumDocumentType.INVOICE) {
    localStorage.store(LocalStorageKeys.INVOICE_CREATION_FORM, values)
  }
}

export const getDocumentCreationForm = (
  documentType: EnumDocumentType,
  customerId?: string
): DocumentFormProps | null => {
  const defaultValues = localStorage.retrieve<DocumentFormProps>(
    documentType === EnumDocumentType.ESTIMATE
      ? LocalStorageKeys.ESTIMATE_CREATION_FORM
      : LocalStorageKeys.INVOICE_CREATION_FORM
  )
  // stored customer must match customerId URL query, if any
  if (!customerId || defaultValues?.customer?.value === customerId) {
    return defaultValues
  }
  return null
}

export const clearDocumentCreationForm = (documentType: EnumDocumentType) => {
  if (documentType === EnumDocumentType.ESTIMATE) {
    localStorage.clear(LocalStorageKeys.ESTIMATE_CREATION_FORM)
  } else if (documentType === EnumDocumentType.INVOICE) {
    localStorage.clear(LocalStorageKeys.INVOICE_CREATION_FORM)
  }
}

export const clearAllDocumentCreationForms = () => {
  localStorage.clear(LocalStorageKeys.ESTIMATE_CREATION_FORM)
  localStorage.clear(LocalStorageKeys.INVOICE_CREATION_FORM)
}
