export enum EnumLifecycleGeneralState {
  CLOSURE = 'closure',
  PAYING = 'paying',
  TRIAL = 'trial',
  ONBOARDING = 'onboarding',
}

export enum EnumLifecycleOnboardingState {
  ACCOUNT_REFUSED = 'account_refused',
  ACCOUNT_VALIDATED = 'account_validated',
  ADDITIONAL_INFORMATION_REQUESTED = 'additional_information_requested',
  UNDER_REVIEW = 'under_review',
  IDENTITY_VERIFICATION_COMPLETED = 'identity_verification_completed',
  IDENTITY_VERIFICATION_STARTED = 'identity_verification_started',
  BIRTHPLACE_DECLARED = 'birthplace_declared',
  FIS = 'fis',
}

export enum EnumLifecycleNextAction {
  EMPTY = 'empty',
  COMPANY = 'company',
  ACTIVITY = 'activity',
  IDENTITY = 'identity',
  UNDER_REVIEW = 'under_review',
  ONBOARDING_REFUSED = 'onboarding_refused',
  ADDITIONAL_INFORMATION_REQUESTED = 'additional_information_requested',
  CARD_ORDER = 'card_order',
  ACTIVATE_CARD = 'activate_card',
  CREDIT_ACCOUNT = 'credit_account',
}

export enum EnumLifecycleStep {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  ADDITIONAL_INFORMATION_REQUESTED = 'additional_information_requested',
  UNDER_REVIEW = 'under_review',
  DISABLED = 'disabled',
  DONE = 'done',
  REFUSED = 'refused',
}

export type CorporateLifecycle = {
  currentState?: {
    general: EnumLifecycleGeneralState
    onboarding: EnumLifecycleOnboardingState
  }
  nextAction?: EnumLifecycleNextAction
  KYCStepStatus?: {
    company:
      | EnumLifecycleStep.DONE
      | EnumLifecycleStep.IN_PROGRESS
      | EnumLifecycleStep.TODO
      | EnumLifecycleStep.REFUSED
    activity:
      | EnumLifecycleStep.DISABLED
      | EnumLifecycleStep.TODO
      | EnumLifecycleStep.DONE
      | EnumLifecycleStep.REFUSED
    identity: EnumLifecycleStep
    card:
      | EnumLifecycleStep.DISABLED
      | EnumLifecycleStep.DONE
      | EnumLifecycleStep.TODO
      | EnumLifecycleStep.REFUSED
  }
  services?: {
    tdae:
      | EnumLifecycleStep.TODO
      | EnumLifecycleStep.IN_PROGRESS
      | EnumLifecycleStep.ADDITIONAL_INFORMATION_REQUESTED
      | EnumLifecycleStep.UNDER_REVIEW
      | EnumLifecycleStep.DISABLED
      | EnumLifecycleStep.DONE
      | EnumLifecycleStep.REFUSED
  }
}
