import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { useCorporateLifecycleQueries } from 'modules/corporate/hooks/useCorporateLifecycleQueries'
import { useCorporateOfferServices } from 'modules/corporate/hooks/useCorporateOfferServices'
import { EnumOfferServiceId } from 'modules/corporate/types/offers'
import { useAdditionalServices } from 'modules/services/hooks/main/useAdditionalServices'
import { useMemo } from 'react'
import {
  IconBank,
  IconHouse,
  IconInvoice,
  IconServices,
} from '../components/_icons/icons'
import { EnumRoutes } from '../routes'
import {
  Menu,
  SidebarMenu,
  SidebarSubMenu,
  SubMenuStatus,
} from '../types/sidebar'
import { useTranslation } from './useTranslation'

export const useSidebarConfig = () => {
  const { t } = useTranslation('common-components')

  const { additionalServices } = useAdditionalServices()

  const {
    data: { isKycComplete },
    isSuccess: isKycLifecycleFetchSuccess,
  } = useCorporateLifecycleQueries()

  const { data: internalAccount } = useInternalAccount()

  const { services } = useCorporateOfferServices()

  const hasCheckFeature = services?.some(
    (service) => service.id === EnumOfferServiceId.check
  )

  const isKycPending = isKycLifecycleFetchSuccess && !isKycComplete

  const kycRestrictedSubMenuStatus = isKycPending
    ? SubMenuStatus.PENDING
    : SubMenuStatus.ACTIVE

  const menus: Menu[] = useMemo(
    () => [
      {
        id: SidebarMenu.HOME,
        icon: <IconHouse />,
        link: EnumRoutes.HOME,
        title: t(`sidebar.menuLabels.${SidebarMenu.HOME}`),
      },
      {
        id: SidebarMenu.ACCOUNT,
        icon: <IconBank />,
        link: EnumRoutes.ACCOUNTS,
        title: t(`sidebar.menuLabels.${SidebarMenu.ACCOUNT}`),
        subMenus: [
          {
            id: SidebarSubMenu.ACCOUNT_HOME,
            link: EnumRoutes.ACCOUNTS,
            status: SubMenuStatus.ACTIVE,
          },
          ...(internalAccount && !internalAccount.isBlockedInvest
            ? [
                {
                  id: SidebarSubMenu.ACCOUNT_TRANSFERS,
                  link: EnumRoutes.ACCOUNT_TRANSFERS,
                  status: kycRestrictedSubMenuStatus,
                },
                {
                  id: SidebarSubMenu.ACCOUNT_CARD,
                  link: EnumRoutes.ACCOUNT_CARD,
                  status: kycRestrictedSubMenuStatus,
                },
                {
                  id: SidebarSubMenu.ACCOUNT_MANDATES,
                  link: EnumRoutes.ACCOUNT_MANDATES,
                  status: kycRestrictedSubMenuStatus,
                },
                ...(hasCheckFeature
                  ? [
                      {
                        id: SidebarSubMenu.ACCOUNT_CHECK,
                        link: EnumRoutes.ACCOUNT_CHECK,
                        status: kycRestrictedSubMenuStatus,
                      },
                    ]
                  : []),
              ]
            : []),
        ],
      },
      {
        id: SidebarMenu.INVOICING,
        icon: <IconInvoice />,
        link: EnumRoutes.INVOICING_DOCUMENTS,
        title: t(`sidebar.menuLabels.${SidebarMenu.INVOICING}`),
        subMenus: [
          {
            id: SidebarSubMenu.INVOICING_HOME,
            link: EnumRoutes.INVOICING_DOCUMENTS,
            status: SubMenuStatus.ACTIVE,
          },
          {
            id: SidebarSubMenu.INVOICING_CUSTOMERS,
            link: EnumRoutes.INVOICING_CUSTOMERS,
            status: SubMenuStatus.ACTIVE,
          },
          {
            id: SidebarSubMenu.INVOICING_SETTINGS,
            link: EnumRoutes.INVOICING_SETTINGS,
            status: SubMenuStatus.ACTIVE,
          },
        ],
      },
      ...((additionalServices || []).length > 0
        ? [
            {
              id: SidebarMenu.SERVICES,
              icon: <IconServices />,
              link: EnumRoutes.SERVICES,
              title: t(`sidebar.menuLabels.${SidebarMenu.SERVICES}`, {
                count: additionalServices?.length,
              }),
              subMenus: [
                {
                  id: SidebarSubMenu.SERVICES_HOME,
                  link: EnumRoutes.SERVICES,
                  status: SubMenuStatus.ACTIVE,
                },
              ],
            },
          ]
        : []),
    ],
    [
      additionalServices,
      hasCheckFeature,
      internalAccount,
      kycRestrictedSubMenuStatus,
      t,
    ]
  )

  return { menus }
}
