import axios from 'axios'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { UseQueryOptions } from 'react-query'
import { CorporatePaymentMethod } from '../types/paymentServiceProvider'
import { EnumBillingQueryKeys } from './keys'

const fetchCorporatePaymentMethods = async (
  corporateId: string,
  accessToken: string
) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/v1/billing/corporate/${corporateId}/payment-service-provider/customer`

  const { data } = await axios.get<CorporatePaymentMethod[] | null>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return data || []
}

export const useCorporatePaymentMethodsQuery = (
  corporateId: string | undefined,
  queryOptions?: UseQueryOptions<CorporatePaymentMethod[], Error>
) => {
  return useQueryWithAuth<CorporatePaymentMethod[], Error>(
    [EnumBillingQueryKeys.PAYMENT_SERVICE_PROVIDER_CUSTOMER],
    async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporatePaymentMethodsQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporatePaymentMethods(corporateId, accessToken)
    },
    {
      enabled: Boolean(corporateId),
      ...queryOptions,
    }
  )
}
