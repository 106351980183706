import { useCallback, useEffect } from 'react'

import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUser.query'
import { useCorporateRecordQuery } from 'modules/corporate/queries/useCorporateRecord.query'

import { useAppContextState } from '../contexts/AppContext'
import { setAmplitudeUserProperties } from '../lib/amplitude'
import { pushGTMProperties } from '../lib/gtm/dataLayer'
import { UserTrackingProperties } from '../types/tracking'

export const useSetUserTrackingProperties = () => {
  const { data: user } = useAuthenticatedUserQuery()
  const { data: corporate } = useCorporateRecordQuery(user?.corporateId)
  const { landingPageParameters } = useAppContextState()

  const setUserTrackingProperties = useCallback(
    (properties: Partial<UserTrackingProperties>) => {
      setAmplitudeUserProperties(properties)
      pushGTMProperties(properties)
    },
    []
  )

  useEffect(() => {
    setUserTrackingProperties({
      corporateId: user?.corporateId,
      affilae_click_id: landingPageParameters?.affilaeClickId,
      awin_click_id: landingPageParameters?.awinClickId,
      gclid: landingPageParameters?.gclid,
      activityCode: corporate?.information?.activityCode,
      activityLabel: corporate?.information?.activityLabel,
      isPhysicalPerson: corporate?.information?.isPhysicalPerson,
      legalFormCode: corporate?.information?.legalFormCode,
      createdAt: corporate?.createdAt,
      status: corporate?.statuses,
      auth0Id: user?.sub,
    })
  }, [
    user,
    corporate,
    setUserTrackingProperties,
    landingPageParameters?.affilaeClickId,
    landingPageParameters?.awinClickId,
    landingPageParameters?.gclid,
  ])
}
