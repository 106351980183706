import { useSidebarConfig } from 'modules/common/hooks/useSidebarConfig'
import React, { PropsWithChildren } from 'react'
import { SidebarContextProvider } from './SidebarContext'

export const SidebarWithConfigContext: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { menus } = useSidebarConfig()

  return (
    <SidebarContextProvider menus={menus}>{children}</SidebarContextProvider>
  )
}
