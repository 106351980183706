export interface FetchAccountsResponse {
  data: Account[]
  extraData: {
    totalProfessionalBalance: number
  }
}

export interface FetchAddAccountWidgetResponse {
  widgetURL: string
}

export interface FetchPinCodeLinkResponse {
  link: string
}

export type FetchGetCodePinResponse =
  | {
      card_pin?: string
    }
  | {
      body: {
        card_pin?: string
      }
    }

export enum EnumLinxoAddWidgetResult {
  CREATED = 'created',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export enum EnumLinxoEditWidgetResult {
  EDITED = 'edited',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export type EnumLinxoWidgetResult =
  | EnumLinxoAddWidgetResult
  | EnumLinxoEditWidgetResult

export interface LinxoWidgetResponse {
  result: EnumLinxoWidgetResult
  status?: 'success'
  connectionId?: string
  queueId?: string
  channelDefinitionId?: string
  accountId?: string
  tdaeRecordSortKey?: string
}

export interface Provider {
  id: string
  name: string
  logoUrl: string
  branchName?: string
}

export interface Account {
  id: string
  connectionId?: string
  createdAt: string
  updatedAt: string
  featured: boolean
  name: string
  source: EnumAccountSource
  iban: string
  currency: string
  status: EnumAccountStatuses
  externalId: string
  corporateId: string
  externalCorporateId: string
  balance: string
  isCardPreordered: boolean
  providerId: string
  type: EnumAccountType
  firstTransactionDate?: string
  isUnderInvest?: boolean
  isBlockedInvest?: boolean
}

export interface AccountWithProvider extends Account {
  provider?: Provider
}

export type PatchAccountPayload = Partial<
  Pick<Account, 'name' | 'type' | 'connectionId'> & { queueId: string }
>

export type SyncLinxoAccountsPayload = Pick<Account, 'connectionId'> & {
  queueId: string
}

export enum EnumAccountSource {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum EnumAccountType {
  PROFESSIONAL = 'professional',
  INDIVIDUAL = 'individual',
}

export enum EnumAccountStatuses {
  // Bankable
  DISCONNECTED = 'DISCONNECTED',
  ACT = 'ACT',

  // Linxo
  NEVER = 'NEVER',
  RUNNING = 'RUNNING',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  SUCCESS = 'SUCCESS',

  // Linxo - Error
  FAILED = 'FAILED',
  AUTH_FAILED = 'AUTH_FAILED',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  PASSWORD_CHANGE_REQUIRED = 'PASSWORD_CHANGE_REQUIRED',
  USER_ACTION_REQUIRED = 'USER_ACTION_REQUIRED',
  CHALLENGE_REQUIRED = 'CHALLENGE_REQUIRED',
  CHALLENGE_TIMED_OUT = 'CHALLENGE_TIMED_OUT',
  CHALLENGE_FAILED = 'CHALLENGE_FAILED',
  CHALLENGE_CANCELLED = 'CHALLENGE_CANCELLED',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  CLOSED = 'CLOSED',
  EMPTY = 'EMPTY',

  // Bankable & Linxo
  BLANK_ERROR = 'BLANK_ERROR',

  // Linxo
  NEW_CHANNEL_AVAILABLE = 'NEW_CHANNEL_AVAILABLE',
}
