import { PropsWithChildren } from 'react'
import { AuthenticatedPageGuard } from './AuthenticatedPageGuard/AuthenticatedPageGuard'
import { NotAuthenticatedPageGuard } from './NotAuthenticatedPageGuard/NotAuthenticatedPageGuard'

interface Props {
  isAuthenticatedPage?: boolean
}

export const AuthGuards: React.FC<PropsWithChildren<Props>> = ({
  isAuthenticatedPage,
  children,
}) =>
  isAuthenticatedPage ? (
    <AuthenticatedPageGuard>{children}</AuthenticatedPageGuard>
  ) : (
    <NotAuthenticatedPageGuard>{children}</NotAuthenticatedPageGuard>
  )
