import Axios from 'axios'
import { UseQueryOptions } from 'react-query'
import { CorporateLifecycle } from '../types/lifecycle'
import { EnumCorporateQueryKeys } from './keys'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'

interface FetchCorporateArgs {
  corporateId: string
  accessToken: string
}

const fetchCorporateLifecycle = async ({
  corporateId,
  accessToken,
}: FetchCorporateArgs) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/v1/users/corporates/${corporateId}/lifecycle`

  const { data } = await Axios.get<CorporateLifecycle>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return data
}

export const useCorporateLifecycleQuery = (
  corporateId?: string,
  queryOptions?: UseQueryOptions<CorporateLifecycle, Error>
) => {
  return useQueryWithAuth<CorporateLifecycle, Error>(
    [EnumCorporateQueryKeys.CORPORATES_LIFECYCLE, corporateId],
    async (context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporateLifecycleQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporateLifecycle({ corporateId, accessToken })
    },
    {
      enabled: !!corporateId,
      ...queryOptions,
    }
  )
}
