import { clearAccessToken } from 'modules/auth/lib/auth0/token'
import { useOnUnMount } from 'modules/common/hooks/useOnUnMount'
import { resetAmplitudeUserProperties } from 'modules/common/lib/amplitude'
import { resetGTMProperties } from 'modules/common/lib/gtm/dataLayer'
import { clearSentryUser } from 'modules/common/lib/sentry/user'
import { clearAllDocumentCreationForms } from 'modules/invoicing/lib/storage/documentCreationForm'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

export const useLogoutFlow = (options?: { redirectTo?: string }) => {
  const { redirectTo = '/connexion' } = options || {}
  const queryClient = useQueryClient()
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  const logout = async () => {
    setLoading(true)
    // Clear all documents creation form from local storage
    clearAllDocumentCreationForms()
    // Clear all user properties from amplitude
    resetAmplitudeUserProperties()
    // Clear all user properties from GTM
    resetGTMProperties()
    clearAccessToken()
    clearSentryUser()
    queryClient.clear()
    await router.replace(redirectTo)
    setLoading(false)
  }

  useOnUnMount(() => setLoading(false))

  return { logout, loading }
}
