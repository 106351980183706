import {
  CorporateLifecycle,
  EnumLifecycleStep,
} from 'modules/corporate/types/lifecycle'

export const getIsKycComplete = (lifeycle?: CorporateLifecycle | null) => {
  if (!lifeycle) {
    return false
  }
  return lifeycle?.KYCStepStatus
    ? Object.values(lifeycle.KYCStepStatus).every((stepStatus) => {
        return stepStatus === EnumLifecycleStep.DONE
      })
    : false
}
