import * as gtm from 'modules/common/lib/gtm/dataLayer'
import { useCallback } from 'react'
import { sendAmplitudeData } from '../lib/amplitude'
import { EventProps } from '../types/tracking'

export const useTrackEvent = () => {
  const trackEvent = useCallback(
    ({ eventType, eventProperties }: EventProps) => {
      sendAmplitudeData(eventType, eventProperties)
      gtm.pushEvent(eventType)
    },
    []
  )

  return trackEvent
}
