import { useMemo } from 'react'
import { ButtonColor, ButtonVariant } from './Button'
import { SPINNER_THEME } from './Spinner.theme'

interface Props {
  variant: ButtonVariant
  color: ButtonColor | undefined
}

export const useSpinnerTheme = ({ variant, color = 'none' }: Props) => {
  const spinnerClasses = useMemo(() => {
    if (color) {
      return {
        className: SPINNER_THEME[variant]?.className,
        ...SPINNER_THEME[variant]?.[color],
      }
    }
  }, [color, variant])

  const isSpinnerOnTop = !['link', 'none'].includes(variant)

  return { isSpinnerOnTop, spinnerClasses }
}
