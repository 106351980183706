import clsx from 'clsx'
import { useOnUnMount } from 'modules/common/hooks/useOnUnMount'
import React, { useRef, useState } from 'react'
import { IconCopyText } from '../_icons/icons'
import { Button } from '../_ui/Button/Button'

const TIMEOUT = 5000

export interface CopyTextProps {
  textToCopy: string
  className?: string
  label?: string
}

export const CopyText: React.FC<CopyTextProps> = ({
  textToCopy,
  className,
  label,
}) => {
  const [isPressed, setIsPressed] = useState(false)
  const timer = useRef<NodeJS.Timer | null>(null)

  const handleClick = async () => {
    setIsPressed(true)
    await navigator.clipboard.writeText(textToCopy)
    timer.current = setTimeout(() => {
      setIsPressed(false)
    }, TIMEOUT)
  }

  useOnUnMount(() => {
    timer.current && clearTimeout(timer.current)
  })

  return (
    <Button
      className={clsx(
        `cursor-pointer px-0 py-0`,
        isPressed ? 'text-success' : 'text-info-600',
        className
      )}
      variant="link"
      type="button"
      onClick={handleClick}
      icon={<IconCopyText />}
    >
      {label}
    </Button>
  )
}
