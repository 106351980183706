import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import Axios from 'axios'
import { UseQueryOptions } from 'react-query'
import { CorporateOffer } from '../types/offers'
import { EnumCorporateQueryKeys } from './keys'

const fetchCorporateOffer = async (
  corporateId: string,
  accessToken: string
) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/v1/users/corporates/${corporateId}/offers`

  const { data } = await Axios.get<CorporateOffer>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return data
}

export const useCorporateOfferQuery = (
  corporateId: string | undefined,
  queryOptions?: UseQueryOptions<CorporateOffer, Error>
) => {
  return useQueryWithAuth<CorporateOffer, Error>(
    [EnumCorporateQueryKeys.CORPORATES_OFFER, corporateId],
    async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error('useCorporateOfferQuery: Missing parameter corporateId')
      }

      return fetchCorporateOffer(corporateId, accessToken)
    },
    {
      enabled: !!corporateId,
      ...queryOptions,
    }
  )
}
