import { PropsWithChildren, useEffect } from 'react'

import { useRequiredAuthDataQueries } from 'modules/auth/hooks/useRequiredAuthDataQueries'
import { useIsMounted } from 'modules/common/hooks/useIsMounted'
import { useRouter } from 'next/router'

import { FullPageLoader } from '../FullPageLoader/FullPageLoader'
import { ErrorReload } from 'modules/common/components/ErrorReload/ErrorReload'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { RedirectAuthenticatedUserWrapper } from '../RedirectAuthenticatedUserWrapper/RedirectAuthenticatedUserWrapper'

export const AuthenticatedPageGuard: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation('common-components')
  const isMounted = useIsMounted()
  const router = useRouter()

  const {
    isFetchError,
    isFetchLoading,
    isUserAuthenticated,
    errorRefetch,
  } = useRequiredAuthDataQueries()

  useEffect(() => {
    if (!isUserAuthenticated) {
      router.replace(EnumRoutes.LOGIN)
    }
  }, [router, isUserAuthenticated])

  if (isFetchError) {
    return (
      <ErrorReload
        className="min-h-screen"
        title={t('authenticatedGuard.error.title')}
        firstDescription={t('authenticatedGuard.error.firstDescription')}
        secondDescription={t('authenticatedGuard.error.secondDescription')}
        onReload={errorRefetch}
      />
    )
  } else if (!isMounted || !isUserAuthenticated || isFetchLoading) {
    return <FullPageLoader />
  } else {
    return (
      <RedirectAuthenticatedUserWrapper>
        {children}
      </RedirectAuthenticatedUserWrapper>
    )
  }
}
