import { useCorporateRecordQuery } from 'modules/corporate/queries/useCorporateRecord.query'
import { Corporate } from 'modules/corporate/types/corporates'
import { useCallback } from 'react'
import { UseQueryOptions } from 'react-query'
import { useAuthenticatedUserQuery } from './useAuthenticatedUser.query'

export const useAuthenticatedUserCorporateQuery = (
  queryOptions?: UseQueryOptions<Corporate, Error>
) => {
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    isSuccess: isUserSuccess,
    refetch: refetchUser,
  } = useAuthenticatedUserQuery()

  const {
    data: corporate,
    isLoading: isCorporateLoading,
    isError: isCorporateError,
    isSuccess: isCorporateSuccess,
    refetch: refetchCorporate,
  } = useCorporateRecordQuery(user?.corporateId, queryOptions)

  const errorRefetch = useCallback(() => {
    if (isUserError) {
      refetchUser()
    } else if (isCorporateError) {
      refetchCorporate()
    }
  }, [isUserError, isCorporateError, refetchUser, refetchCorporate])

  return {
    data: { user, corporate },
    isLoading: isUserLoading || isCorporateLoading,
    isError: isUserError || isCorporateError,
    isSuccess: isUserSuccess && isCorporateSuccess,
    errorRefetch,
  }
}
