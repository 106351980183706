import { SpinnerProps } from '../Spinner/Spinner'
import { ButtonColor, ButtonVariant } from './Button'

type SpinnerTheme = {
  [key in ButtonVariant]?: { className: string } & Partial<
    {
      [key in ButtonColor]: Partial<
        Pick<SpinnerProps, 'circleClassName' | 'circleBgClassName'>
      >
    }
  >
}

export const SPINNER_THEME: SpinnerTheme = {
  contained: {
    className: 'absolute',
    none: {
      circleClassName: 'text-info-200',
      circleBgClassName: 'text-info-300',
    },
    active: {
      circleClassName: 'text-white',
      circleBgClassName: 'text-primary-600',
    },
    warning: {
      circleClassName: 'text-white',
      circleBgClassName: 'text-warning-600',
    },
    danger: {
      circleClassName: 'text-white',
      circleBgClassName: 'text-danger-600',
    },
  },
  outline: {
    className: 'absolute',
    none: {
      circleClassName: 'text-info-200',
      circleBgClassName: 'text-info-300',
    },
    active: {
      circleClassName: 'text-info-200',
      circleBgClassName: 'text-info-300',
    },
    warning: {
      circleClassName: 'text-warning-200',
      circleBgClassName: 'text-warning-600',
    },
    danger: {
      circleClassName: 'text-danger-200',
      circleBgClassName: 'text-danger',
    },
  },
  icon: {
    className: 'absolute',
    none: {
      circleClassName: 'text-info-200',
      circleBgClassName: 'text-info-300',
    },
    active: {
      circleClassName: 'text-info-200',
      circleBgClassName: 'text-info-300',
    },
    warning: {
      circleClassName: 'text-warning-200',
      circleBgClassName: 'text-warning-600',
    },
    danger: {
      circleClassName: 'text-danger-200',
      circleBgClassName: 'text-danger',
    },
  },
}
