import { Customer } from './customers'
import { InvoicingSettings } from './settings'

export type DocumentRow = Pick<
  Document,
  | 'id'
  | 'createdAt'
  | 'dueDate'
  | 'isLate'
  | 'status'
  | 'totalWithoutVAT'
  | 'documentNumber'
  | 'title'
  | 'type'
> & {
  customerName: string
}
export interface Document {
  id: string
  type: EnumDocumentType
  status: EnumDocumentStatus
  isLate?: boolean
  title: string
  createdAt: string
  validatedAt: string
  documentNumber?: string
  documentPDF?: string
  VATRate?: number
  totalWithoutVAT: number
  totalWithVAT: number
  totalVAT: number
  discountRate: number
  dueDate: string
  customerId?: string
  customer?: Customer
  items: DocumentItem[]
  settings: InvoicingSettings
  updatedAt: string
  corporateId?: string
}

export interface DocumentItem {
  currency: string
  description?: string
  name: string
  quantity: number
  totalVAT: number
  totalWithVAT: number
  totalWithoutVAT: number
  unit?: string
  unitPriceWithoutVAT: number
  VATRate: number
}

export interface DocumentPreview {
  base64PDF: string
}

export interface DocumentHTMLPreview {
  html: string
}

export type DocumentItemPayload = Pick<
  DocumentItem,
  | 'currency'
  | 'description'
  | 'name'
  | 'quantity'
  | 'unit'
  | 'unitPriceWithoutVAT'
  | 'VATRate'
>

export type PostDocumentPayload = {
  type: EnumDocumentType
  corporateId?: string
  items?: DocumentItemPayload[]
  discountRate?: number
  title?: string
  customerId?: string
}

export type PatchDocumentPayload = Partial<
  Omit<PostDocumentPayload, 'corporateId'>
> & {
  status?: EnumDocumentStatus
}

export interface PostDocumentValidationPayload {
  validatedAt: string
  documentNumber: string
  estimateAcceptationDays?: number
  invoicePaymentDays?: number
  corporateId?: string
}

export enum EnumDocumentType {
  INVOICE = 'INVOICE',
  ESTIMATE = 'ESTIMATE',
}

export enum EnumDocumentStatus {
  DRAFT = 'DRAFT',
  WAITING = 'WAITING',
  SENT = 'SENT',
  PAID = 'PAID',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
}

export const InvoiceStatusTypes = Object.values(EnumDocumentStatus).filter(
  (value) => value !== EnumDocumentStatus.ACCEPTED
)

export const EstimateStatusTypes = Object.values(EnumDocumentStatus).filter(
  (value) => value !== EnumDocumentStatus.PAID
)

export enum EnumDocumentTabs {
  INVOICES_TAB = 'invoiceDocuments',
  ESTIMATES_TAB = 'estimateDocuments',
}

export enum EnumDocumentTypeSlugs {
  INVOICE_SLUG = 'facture',
  ESTIMATE_SLUG = 'devis',
}

export const DOCUMENT_SLUG_TYPES = {
  [EnumDocumentTypeSlugs.INVOICE_SLUG]: EnumDocumentType.INVOICE,
  [EnumDocumentTypeSlugs.ESTIMATE_SLUG]: EnumDocumentType.ESTIMATE,
}

export const DOCUMENT_TYPE_SLUGS = {
  [EnumDocumentType.INVOICE]: EnumDocumentTypeSlugs.INVOICE_SLUG,
  [EnumDocumentType.ESTIMATE]: EnumDocumentTypeSlugs.ESTIMATE_SLUG,
}

export enum EnumDocumentConfigurationStep {
  VAT = 'VAT',
  ESTIMATE_CONDITIONS = 'ESTIMATE_CONDITIONS',
  INVOICE_CONDITIONS = 'INVOICE_CONDITIONS',
  APPEARANCE = 'APPEARANCE',
}
