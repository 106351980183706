import { CA_CONFIG } from 'config/ca'
import { LCL_CONFIG } from 'config/lcl'
import { BLANK_CONFIG } from './blank'
import { ConfigType, EnumBrand } from './types'

const buildTimeConfigs: Record<EnumBrand, ConfigType> = {
  blank: BLANK_CONFIG,
  ca: CA_CONFIG,
  lcl: LCL_CONFIG,
}

export const buildTimeBrand: EnumBrand =
  (process.env.NEXT_PUBLIC_BRAND as EnumBrand) ||
  (process.env.STORYBOOK_BRAND as EnumBrand) ||
  EnumBrand.BLANK

export const buildTimeConfig: ConfigType = buildTimeConfigs[buildTimeBrand]
