const path = require('path')
const dayjs = require('dayjs')

module.exports = {
  i18n: {
    defaultLocale: 'fr',
    locales: ['fr'],
    interpolation: {
      format(value, format) {
        // https://www.i18next.com/translation-function/formatting
        if (value instanceof Date) {
          return dayjs(value).format(format)
        }

        return value
      },
      defaultVariables: {},
    },
  },
  react: { useSuspense: false },
  serializeConfig: false,
  localePath: path.resolve('./public/static/locales'),
}
