import Axios from 'axios'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { UseQueryOptions } from 'react-query'
import { Account } from '../types/accounts'
import { EnumBankQueryKeys } from './keys'

const fetchAccount = async (accountId: string, accessToken: string) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/v1/bank/accounts/${accountId}`

  const { data } = await Axios.get<Account>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return data
}

export const useAccountQuery = (
  accountId?: string,
  queryOptions?: UseQueryOptions<Account, Error>
) => {
  return useQueryWithAuth<Account, Error>(
    [EnumBankQueryKeys.ACCOUNTS_RECORD, accountId],
    async (context, accessToken) => {
      if (!accountId) {
        throw new Error('useQueryWithAuth: Missing parameter accountId')
      }

      return fetchAccount(accountId, accessToken)
    },
    {
      enabled: !!accountId,
      ...queryOptions,
    }
  )
}
