import { useRequiredAuthDataQueries } from 'modules/auth/hooks/useRequiredAuthDataQueries'
import { EnumOfferType } from 'modules/billing/types/offers'

export enum EnumUserOnboardingState {
  PHONE_VERIFICATION = 'PHONE_VERIFICATION',
  COMPANY_SELECTION = 'COMPANY_SELECTION',
  MANAGEMENT_OFFER_SEPA_MANDATE_CONFIRMATION = 'MANAGEMENT_OFFER_SEPA_MANDATE_CONFIRMATION',
  COMPLETED = 'COMPLETED',
}

export const useAuthenticatedUserOnboardingState = () => {
  const {
    data: { user, corporate, corporatePaymentMethods, corporateOffer },
  } = useRequiredAuthDataQueries()

  if (user && !user.phoneNumberVerified) {
    return EnumUserOnboardingState.PHONE_VERIFICATION
  } else if (corporate && !corporate.information?.siren) {
    return EnumUserOnboardingState.COMPANY_SELECTION
  } else if (
    corporateOffer?.offerType === EnumOfferType.STRICTLY_MANAGEMENT_TOOLS &&
    corporatePaymentMethods &&
    corporatePaymentMethods.length === 0
  ) {
    return EnumUserOnboardingState.MANAGEMENT_OFFER_SEPA_MANDATE_CONFIRMATION
  } else {
    return EnumUserOnboardingState.COMPLETED
  }
}
