export interface Offer {
  id: string
  reference: string
  monthlyPrice: number
  VATRate: number
  trialDuration: number
  offerPackages: Array<OfferPackage>
  offerType: EnumOfferType
}

export interface OfferPackage {
  includedAmount: number | null
  canExceedAmount: boolean
  includedCount: number | null
  canExceedCount: boolean
  specificFixedPrice: number | null
  specificVariablePrice: number | null
  serviceId: EnumOfferServiceId
  category: EnumOfferPackageCategory
  type: EnumOfferPackageType
}

export enum EnumOfferPackageCategory {
  FEATURE = 'FEATURE',
  TRANSACTION = 'TRANSACTION',
  IRREGULAR_OPERATION = 'IRREGULAR_OPERATION',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_CARD = 'BANK_CARD',
  BLANK_SUPPORT = 'BLANK_SUPPORT',
  PARTNER_SUPPORT = 'PARTNER_SUPPORT',
  PARTNER_INSURANCE = 'PARTNER_INSURANCE',
  PARTNER_OPERATION = 'PARTNER_OPERATION',
  INSURRANCE_ASSISTANCE = 'INSURRANCE_ASSISTANCE',
  FINANCING_PAYMENT = 'FINANCING_PAYMENT',
  ADMINISTRATIVE_ACCOUNTING = 'ADMINISTRATIVE_ACCOUNTING',
  PERSONAL_DATA = 'PERSONAL_DATA',
}

export enum EnumOfferPackageType {
  BANKING = 'BANKING',
  SUPPORT_ASSITANCE = 'SUPPORT_ASSITANCE',
  INSURANCE_GURANTEE = 'INSURANCE_GURANTEE',
  MANAGEMENT = 'MANAGEMENT',
  SERVICE = 'SERVICE',
  LEGAL = 'LEGAL',
}

export enum EnumOfferType {
  BASIC_CARD = 'BASIC_CARD',
  EXTENDED_CARD = 'EXTENDED_CARD',
  MANAGEMENT_TOOLS = 'MANAGEMENT_TOOLS',
  STRICTLY_MANAGEMENT_TOOLS = 'STRICTLY_MANAGEMENT_TOOLS',
}

export enum EnumOfferServiceId {
  CHECK = 'check',
  CARD_FRAUD = 'cardFraud',
  MATERIAL_GUARANTEE = 'materialGuarantee',
  MANUFACTURE_WARRANTY = 'manufacturerWarranty',
  TRAVEL_ABROAD_OTHER_GUARANTEES = 'travelAbroadOtherGuarantees',
  TRAVEL_ABROAD_HEALTH_GUARANTEES = 'travelAbroadHealthGuarantees',
  HOSPITALIZATION_GUARANTEE = 'hospitalizationGuarantee',
  ADMINISTRATIVE_ASSISTANCE = 'administrativeAssistance',
  EMAIL_SUPPORT = 'emailSupport',
  INSTANT_MESSAGING = 'instantMessaging',
  PHONE_SUPPORT = 'phoneSupport',
  FAQ = 'FAQ',
  ACCOUNTING = 'accounting',
  PROFRESSIONAL_LIABILITY = 'professionalLiability',
  HEALTH_CARE = 'healthcare',
  MULTIRISK_INSURANCE = 'multiriskInsurance',
  PROVIDENT_INSURANCE = 'providentInsurance',
  TEN_YEAR_GUARANTEE = 'tenYearGuarantee',
  FACTORING = 'factoring',
  CREDIT = 'credit',
  PAYMENT_TERMINAL = 'paymentTerminal',
  NOTICE_BLACK_CARD = 'noticeBlackCard',
  NOTICE_WHITE_CARD = 'noticeWhiteCard',
  URSSAF = 'urssaf',
  BANK_ACCOUNT = 'bankAccount',
  BANKING_AGGREGATION = 'bankingAggregation',
  MANAGEMENT_PLATFORM = 'managementPlatform',
  TRANSFERT = 'transfer',
  DIRECT_DEBIT = 'directDebit',
  BASIC_CARD = 'basicCard',
  EXTENDED_CARD = 'extendedCard',
  FINANCING = 'financing',
  OTHER_SERVICES_LCL = 'otherServicesLCL',
  REVENUE_GUARANTEE = 'revenueGuarantee',
}

export interface FetchPublicOffersResponse {
  data: Offer[]
}
