import { useCorporateOfferContext } from '../contexts/CorporateOfferContext'

export const useCorporateOfferServices = () => {
  const {
    isLoading,
    isError,
    refetch,
    corporateOffer,
  } = useCorporateOfferContext()

  return { isLoading, isError, refetch, services: corporateOffer?.services }
}
