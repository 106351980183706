export enum EnumRoutes {
  ONBOARDING_COMPANY = '/votre-entreprise',
  PHONE_NUMBER_VERIFICATION = '/verification-telephone',
  HOME = '/accueil',
  SERVICES = '/services',
  MANDATORY_INFORMATION = '/information-compte',
  OFFER_SELECTION = '/selection-offre',

  // Auth Routes
  LOGIN = '/connexion',
  SCA_LOGIN = '/connexion-sca',
  SIGNUP = '/inscription',
  SECURING_DEVICE = '/securisation-appareil',
  KYC_ROOT = '/ouverture-compte',
  ACCOUNT_BLOCK = '/blocage-compte',
  EMAIL_CONFIRMATION_ROUTE = '/confirmation-email',
  EMAIL_VERIFICATION = '/verification-email',
  PASSWORD_CHANGE_REQUEST = '/demande-changement-mot-de-passe',
  PASSWORD_CHANGE_CONFIRM = '/demande-changement-mot-de-passe-confirmation',

  // Accounts routes
  ACCOUNTS = '/comptes',
  ACCOUNT_CARD = '/comptes/carte',
  ACCOUNT_MANDATES = '/comptes/prelevements',
  ACCOUNT_CHECK = '/comptes/cheque',
  ACCOUNT_TRANSFERS = '/comptes/virements',
  ACCOUNT_NEW_TRANSFER = '/comptes/virements/nouveau-virement',

  // Invoicing routes
  INVOICING_DOCUMENTS = '/facturation/documents',
  INVOICING_DOCUMENTS_CONFIGURATION = '/facturation/documents/configuration',
  INVOICING_DOCUMENTS_SLUG = '/facturation/creation/[documentTypeSlug]',
  INVOICING_CUSTOMERS = '/facturation/clients',
  INVOICING_SETTINGS = '/facturation/parametres/mentions-generales',

  // Management offer routes
  MANAGEMENT_TOOLS_LOGIN = '/gestion-connexion',
  MANAGEMENT_TOOLS_SIGNUP = '/gestion-inscription',
}
