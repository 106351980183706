import { buildTimeConfig } from 'config'
import { useCorporateOfferServices } from 'modules/corporate/hooks/useCorporateOfferServices'
import { EnumOfferServiceId } from 'modules/corporate/types/offers'

export const useSupportTouchpoints = () => {
  const { isLoading, services } = useCorporateOfferServices()

  const phoneSupport = services?.find(
    (service) => service.id === EnumOfferServiceId.phoneSupport
  )?.phone

  const emailSupport = buildTimeConfig.services.find(
    (service) => service.id === EnumOfferServiceId.emailSupport
  )?.email

  const faqURL = buildTimeConfig.services.find(
    (service) => service.id === EnumOfferServiceId.FAQ
  )?.redirectionUrl

  return {
    phoneSupport,
    emailSupport,
    faqURL,
    isLoading,
  }
}
