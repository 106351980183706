import { TFunctionResult, TOptions } from 'i18next'
import _ from 'lodash'
import { useCallback } from 'react'
import {
  DefaultNamespace,
  Namespace,
  TFuncKey,
  // eslint-disable-next-line no-restricted-imports
  useTranslation as usei18nTranslation,
  UseTranslationOptions,
} from 'react-i18next'
import { useAppContextState } from '../contexts/AppContext'

export function useTranslation<N extends Namespace = DefaultNamespace>(
  ns?: N,
  options?: UseTranslationOptions
) {
  const { t, ...useTranslationResponse } = usei18nTranslation(ns, options)
  const { brand } = useAppContextState()

  const translate = useCallback(
    <
      TKeys extends TFuncKey<N> | TemplateStringsArray extends infer A
        ? A
        : never,
      TDefaultResult extends TFunctionResult | React.ReactNode = string
    >(
      key: TKeys | TKeys[],
      tOptions?: TOptions
    ) => {
      if (_.isArray(key)) {
        const keysWithBrand = []
        for (const k of key) {
          keysWithBrand.push(`${k}%${brand}%` as TKeys)
          keysWithBrand.push(k)
        }
        return t<TKeys, TDefaultResult>(keysWithBrand, tOptions)
      } else {
        const keyWithBrand = `${key}%${brand}%` as TKeys
        return t<TKeys, TDefaultResult>([keyWithBrand, key], tOptions)
      }
    },
    [brand, t]
  )

  return { ...useTranslationResponse, t: translate }
}
