import { ScaAction } from 'modules/auth/types/sca'

export interface FetchBeneficiariesResponse {
  data: Beneficiary[]
}

export interface PatchBeneficiaryPayload {
  creditor: Omit<Creditor, 'isMyAccount'>
}

export interface PostBeneficiaryResponse {
  request: Beneficiary & {
    virtualAccountReference: string
  }
  result?: Beneficiary
  scaAction?: ScaAction
}
export interface Beneficiary {
  id: string
  creditorAgent: CreditorAgent
  creditor: Creditor
  creditorAccount: CreditorAccount
}
export interface CreditorAgent {
  bicFi: string
}
export interface Creditor {
  lastName?: string
  firstName?: string
  nickName?: string
  isMyAccount: boolean
}
export interface CreditorAccount {
  iban: string
}

export interface BeneficiaryRow {
  id: string
  name: string
  iban: string
  bic: string
}

export interface BeneficiaryFormProps {
  name: Creditor['lastName']
  iban: CreditorAccount['iban']
  bic: CreditorAgent['bicFi']
}

type PostCreditorPayload = Omit<Creditor, 'firstName' | 'nickName'>

export type PostBeneficiaryPayload = Omit<Beneficiary, 'id' | 'creditor'> & {
  creditor: PostCreditorPayload
}

export interface PatchBeneficiaryPayload {
  creditor: Omit<Creditor, 'isMyAccount'>
}

export interface FetchIbanValidationResponse {
  bic: string
  bank: string
  branch?: string
  address: string
  city?: string
  state?: null
  zip?: string
  phone: string
  fax?: string
  www?: string
  email?: string
  country?: string
  country_iso: string
  account: string
  bank_code: string
  branch_code?: string
}

export enum IbanValidationError {
  ACCOUNT_NUMBER_DIGIT_NOT_CORRECT = 'account_number_digit_not_correct',
  IBAN_DIGIT_NOT_CORRECT = 'iban_digit_not_correct',
  IBAN_LENGTH_NOT_CORRECT = 'iban_length_not_correct',
  IBAN_STRUCTURE_NOT_CORRECT = 'iban_structure_not_correct',
  IBAN_CONTAINS_ILLEGAL_CHARACTERS = 'iban_contains_illegal_characters',
  COUNTRY_DO_NOT_SUPPORT_IBAN_STANDART = 'country_do_not_support_iban_standart',
  UNSUPPORTED_SEPA = 'unsupported_sepa',
}

export interface BeneficiaryInformation {
  beneficiaryName: string
  iban: string
  bic: string
  bankName: string
  address: string
  locality?: string
}
