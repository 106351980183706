export enum SidebarMenu {
  HOME = 'HOME',
  ACCOUNT = 'ACCOUNT',
  INVOICING = 'INVOICING',
  SERVICES = 'SERVICES',
}

export enum SidebarSubMenu {
  ACCOUNT_HOME = 'ACCOUNT_HOME',
  ACCOUNT_TRANSFERS = 'ACCOUNT_TRANSFERS',
  ACCOUNT_CARD = 'ACCOUNT_CARD',
  ACCOUNT_MANDATES = 'ACCOUNT_MANDATES',
  ACCOUNT_CHECK = 'ACCOUNT_CHECK',
  INVOICING_HOME = 'INVOICING_HOME',
  INVOICING_CUSTOMERS = 'INVOICING_CUSTOMERS',
  INVOICING_SETTINGS = 'INVOICING_SETTINGS',
  SERVICES_HOME = 'SERVICES_HOME',
}

export interface SubMenu {
  id: SidebarSubMenu
  link: string
  status: SubMenuStatus
}

export interface Menu {
  id: SidebarMenu
  icon: JSX.Element
  link: string
  subMenus?: SubMenu[]
  title: string
}

export enum SubMenuStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}
