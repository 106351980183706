import { groupBy } from 'modules/common/lib/helpers/group'
import { useCorporateOfferServices } from 'modules/corporate/hooks/useCorporateOfferServices'
import { EnumOfferServiceType } from 'modules/corporate/types/offers'
import { useMemo } from 'react'

export const useAdditionalServices = () => {
  const { isError, isLoading, refetch, services } = useCorporateOfferServices()

  const additionalServices = useMemo(() => {
    return services?.filter(
      (service) => service.type === EnumOfferServiceType.SERVICE
    )
  }, [services])

  const servicesByCategory = useMemo(() => {
    if (additionalServices) {
      return groupBy(additionalServices, (service) => service.category)
    }
  }, [additionalServices])

  return {
    additionalServices,
    servicesByCategory,
    isLoading,
    isError,
    errorRefetch: refetch,
  }
}
