import { getAccessToken, isAccessTokenExpired } from '../lib/auth0/token'
import { retryFn } from '../lib/helpers'
import { useRefreshTokenMutation } from '../mutations/useRefreshToken.mutation'
import { useCallback } from 'react'
import { buildRefreshTokenScope } from '../lib/auth0/scope'
import { useLogoutFlow } from '../flows/useLogoutFlow'

export interface FreshTokenOptions {
  force: boolean
  withScaReset?: boolean
}

let isRefreshing = false

/**
 * GET FRESH TOKEN
 * Used to fetch a valid token
 * Race condition is handled in refreshToken process
 */
export const useFreshToken = () => {
  const { logout } = useLogoutFlow()

  const { mutateAsync: refreshToken } = useRefreshTokenMutation({
    onError: logout,
  })

  const getFreshToken = useCallback(
    async (
      { force, withScaReset }: FreshTokenOptions = { force: false }
    ): Promise<string | null> => {
      const accessToken = getAccessToken()

      if (isRefreshing) {
        return retryFn(getFreshToken)
      }

      if (accessToken && (force || isAccessTokenExpired(accessToken))) {
        try {
          isRefreshing = true

          const scope = buildRefreshTokenScope({
            withScaReset,
          })

          await refreshToken({ scope })
        } catch {
          return null
        } finally {
          isRefreshing = false
        }
      }

      return getAccessToken() || null
    },
    [refreshToken]
  )

  return getFreshToken
}
