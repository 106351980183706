import {
  EnumOfferServiceCategory,
  EnumOfferServiceId,
  EnumOfferServiceType,
} from 'modules/corporate/types/offers'
import { externalLinks } from '_constants'
import { ConfigType } from './types'

export const LCL_CONFIG: ConfigType = {
  internalBankName: 'Essentiel Pro',
  hasSubBanks: false,
  signupConfig: {
    shouldDisplayOperatedBy: true,
    shouldDisplayCommercialSolicitationOptIn: true,
    canAccessManagementOffer: true,
  },
  isScaEnabledForBrand: false,
  services: [
    // Support services
    {
      id: EnumOfferServiceId.emailSupport,
      category: EnumOfferServiceCategory.BLANK_SUPPORT,
      type: EnumOfferServiceType.SUPPORT_ASSISTANCE,
      email: externalLinks.supportEmail,
      reference: '',
      recurrence: false,
      variablePrice: 0,
      fixedPrice: 0,
      VATRate: 0,
    },
    {
      id: EnumOfferServiceId.FAQ,
      category: EnumOfferServiceCategory.BLANK_SUPPORT,
      type: EnumOfferServiceType.SUPPORT_ASSISTANCE,
      redirectionUrl: externalLinks.faq,
      reference: '',
      recurrence: false,
      variablePrice: 0,
      fixedPrice: 0,
      VATRate: 0,
    },
  ],
}
