import Cookies from 'js-cookie'
import { CookieKeys } from '_constants'

const retrieve = (name: CookieKeys) => {
  return Cookies.get(name)
}

const retrieveJSON = <TReturn>(name: CookieKeys): TReturn | undefined => {
  return Cookies.getJSON(name)
}

const store = (
  name: CookieKeys,
  value: string | Record<string, unknown>,
  options?: Cookies.CookieAttributes
) => {
  return Cookies.set(name, value, options)
}

const clear = (name: CookieKeys) => {
  return Cookies.remove(name)
}

const cookieStorage = { store, retrieve, retrieveJSON, clear }
export default cookieStorage
