import { useQuery, UseQueryOptions } from 'react-query'
import { FreshTokenOptions, useFreshToken } from '../hooks/useFreshToken'
import { getAccessToken, getAuthenticatedUser } from '../lib/auth0/token'
import { AuthenticatedUser } from '../types/auth0'
import { EnumAuthQueryKeys } from './keys'

export const useAuthenticatedUserQuery = (
  queryOptions?: UseQueryOptions<AuthenticatedUser | null, Error>,
  getFreshTokenOptions?: FreshTokenOptions
) => {
  const getFreshToken = useFreshToken()
  const token = getAccessToken()

  return useQuery<AuthenticatedUser | null, Error>(
    [EnumAuthQueryKeys.AUTHENTICATED_USER],
    async () => {
      const accessToken = await getFreshToken(getFreshTokenOptions)

      if (!accessToken) {
        throw new Error('useAuthenticatedUserQuery: Token is missing')
      }

      return getAuthenticatedUser(accessToken)
    },
    {
      retry: false,
      enabled: !!token,
      placeholderData: token ? getAuthenticatedUser(token) : null,
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  )
}
