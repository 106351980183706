import { ButtonColor, ButtonVariant } from './Button'

type ButtonTheme = {
  [key in ButtonVariant]: { base: string } & {
    [key in ButtonColor]: {
      default: string
      disabled: string
      loading?: string
    }
  }
}

export const BUTTON_THEME: ButtonTheme = {
  contained: {
    base:
      'inline-flex justify-center items-center relative rounded-md font-headings font-bold text-sm border-2 border-solid p-4 appearance-none',
    none: {
      default: '',
      disabled: 'text-info-300 border-info-100 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    active: {
      default:
        'text-white bg-primary border-primary hover:bg-primary-600 hover:border-primary-600',
      disabled: 'text-info-300 bg-info-100 border-info-100 cursor-default',
      loading: 'bg-primary border-primary cursor-default pointer-events-none',
    },
    warning: {
      default:
        'text-white bg-warning border-warning hover:bg-warning-600 hover:border-warning-600',
      disabled:
        'text-warning-400 bg-warning-200 border-warning-200 cursor-default',
      loading: 'bg-warning border-warning cursor-default pointer-events-none',
    },
    danger: {
      default:
        'text-white bg-danger border-danger hover:bg-danger-600 hover:border-danger-600',
      disabled:
        'text-danger-400 bg-danger-200 border-danger-200 cursor-default',
      loading: 'bg-danger border-danger cursor-default pointer-events-none',
    },
  },
  outline: {
    base:
      'inline-flex justify-center items-center relative rounded-md font-headings font-bold text-sm border-2 border-solid p-4 appearance-none',
    none: {
      default: '',
      disabled: 'border-info-100 text-info-300 cursor-default',
      loading: 'border-info-300 cursor-default pointer-events-none',
    },
    active: {
      default: 'border-info-300 hover:border-info text-info-600',
      disabled: 'border-info-100 text-info-300 cursor-default',
      loading: 'border-info-300 cursor-default pointer-events-none',
    },
    warning: {
      default:
        'border-warning hover:border-warning-600 text-info-600 hover:text-black',
      disabled: 'border-warning-200 text-warning-400 cursor-default',
      loading: 'border-warning cursor-default pointer-events-none',
    },
    danger: {
      default:
        'border-danger hover:border-danger-600 text-info-600 hover:text-black',
      disabled: 'border-danger-200 text-danger-400 cursor-default',
      loading: 'border-danger cursor-default pointer-events-none',
    },
  },
  link: {
    base:
      'inline-flex justify-center items-center relative font-headings font-bold text-sm p-4 appearance-none',
    none: {
      default: '',
      disabled: 'text-info-300 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    active: {
      default: 'text-primary hover:text-primary-600',
      disabled: 'text-info-300 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    warning: {
      default: 'text-warning hover:text-warning-600',
      disabled: 'text-warning-400 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    danger: {
      default: 'text-danger hover:text-danger-600',
      disabled: 'text-danger-400 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
  },
  icon: {
    base:
      'inline-flex justify-center items-center items-center relative rounded-md font-headings font-bold text-sm border-2 border-solid appearance-none',
    none: {
      default: '',
      disabled: 'border-info-100 text-info-300 cursor-default',
      loading: 'border-info-300 cursor-default pointer-events-none',
    },
    active: {
      default: 'border-info-300 hover:border-info text-info-600',
      disabled: 'border-info-100 text-info-300 cursor-default',
      loading: 'border-info-300 cursor-default pointer-events-none',
    },
    warning: {
      default:
        'border-warning hover:border-warning-600 text-info-600 hover:text-black',
      disabled: 'border-warning-200 text-warning-400 cursor-default',
      loading: 'border-warning cursor-default pointer-events-none',
    },
    danger: {
      default:
        'border-danger hover:border-danger-600 text-info-600 hover:text-black',
      disabled: 'border-danger-200 text-danger-400 cursor-default',
      loading: 'border-danger cursor-default pointer-events-none',
    },
  },
  none: {
    base: 'relative appearance-none',
    none: {
      default: '',
      disabled: 'text-info-300 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    active: {
      default: 'text-primary hover:text-primary-600',
      disabled: 'text-info-300 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    warning: {
      default: 'text-warning hover:text-warning-600',
      disabled: 'text-warning-400 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    danger: {
      default: 'text-danger hover:text-danger-600',
      disabled: 'text-danger-400 cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
  },
}
