import { TokenResponse } from 'auth0'
import axios, { AxiosError } from 'axios'
import { Auth0Error } from 'modules/auth/types/auth0'
import { useMutation, UseMutationOptions } from 'react-query'

type TokenPayload = Pick<TokenResponse, 'scope'>

const refreshToken = async ({ scope }: TokenPayload) => {
  const { data } = await axios.post<void>('/api/auth/refreshToken', {
    scope,
  })

  return data
}

export const useRefreshTokenMutation = (
  mutationOptions?: UseMutationOptions<
    void,
    AxiosError<Auth0Error>,
    TokenPayload
  >
) => {
  return useMutation<void, AxiosError<Auth0Error>, TokenPayload>(
    async (args) => refreshToken(args),
    mutationOptions
  )
}
