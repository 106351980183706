import { PropsWithChildren, useEffect } from 'react'

import { useRequiredAuthDataQueries } from 'modules/auth/hooks/useRequiredAuthDataQueries'
import { useIsMounted } from 'modules/common/hooks/useIsMounted'
import { EnumRoutes } from 'modules/common/routes'
import { useRouter } from 'next/router'

import { FullPageLoader } from '../FullPageLoader/FullPageLoader'
import { RedirectAuthenticatedUserWrapper } from '../RedirectAuthenticatedUserWrapper/RedirectAuthenticatedUserWrapper'

export const NotAuthenticatedPageGuard: React.FC<PropsWithChildren> = ({
  children,
}) => {
  // Need to check is page mounted on client side to avoid react hydration error https://nextjs.org/docs/messages/react-hydration-error
  // React Hydration article: https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  const isMounted = useIsMounted()
  const router = useRouter()

  const {
    isUserAuthenticated,
    isFetchLoading,
    isFetchError,
  } = useRequiredAuthDataQueries()

  useEffect(() => {
    if (isFetchError) {
      router.replace(EnumRoutes.HOME)
    }
  }, [isFetchError, router])

  if (!isMounted || isFetchLoading) {
    return <FullPageLoader />
  } else if (!isUserAuthenticated) {
    return <>{children}</>
  } else {
    return (
      <RedirectAuthenticatedUserWrapper>
        {children}
      </RedirectAuthenticatedUserWrapper>
    )
  }
}
