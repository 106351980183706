import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react'

export interface FeedbackAlertContext {
  type: 'error' | 'success'
  title?: string
  description: string
}

interface FeedbackAlertActions {
  showAlert: (payload: FeedbackAlertContext) => void
  hideAlert: () => void
}

const FeedbackAlertStateContext = createContext<FeedbackAlertContext | null>(
  null
)
const FeedbackAlertActionsContext = createContext<
  FeedbackAlertActions | undefined
>(undefined)

const FeedbackAlertContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<FeedbackAlertContext | null>(null)

  const hideAlert = useCallback(() => setState(null), [])

  const showAlert = useCallback(
    (payload: FeedbackAlertContext) => setState(payload),
    []
  )

  return (
    <FeedbackAlertStateContext.Provider value={state}>
      <FeedbackAlertActionsContext.Provider value={{ hideAlert, showAlert }}>
        {children}
      </FeedbackAlertActionsContext.Provider>
    </FeedbackAlertStateContext.Provider>
  )
}

const useFeedbackAlertContextState = () => {
  const context = useContext(FeedbackAlertStateContext)
  if (context === undefined) {
    throw new Error(
      'useFeedbackAlertContextState must be used within a FeedbackAlertContextProvider'
    )
  }
  return context
}
const useFeedbackAlertContextDispatch = () => {
  const context = useContext(FeedbackAlertActionsContext)
  if (context === undefined) {
    throw new Error(
      'useFeedbackAlertContextDispatch must be used within a FeedbackAlertContextProvider'
    )
  }
  return context
}

export {
  FeedbackAlertContextProvider,
  useFeedbackAlertContextState,
  useFeedbackAlertContextDispatch,
}
