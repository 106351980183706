import { ExternalLinks } from './type'

const faqDomainUrl = process.env.NEXT_PUBLIC_FAQ_DOMAIN || ''

export const CA_EXTERNAL_LINKS: ExternalLinks = {
  cguLink:
    'https://assets.website-files.com/62bd73fb692211e3172975e4/633aed4914b3c41ea124cd52_CR%20-%20CGU_Plateforme%20de%20gestion%20Okali%20x%20Client%20final_VF%20(1).pdf',
  bankingOrganizationPrivacyPolicyLink:
    'https://assets.website-files.com/62bd73fb692211e3172975e4/633c5a4dd5457ace07c4d08f_Politique%20de%20protection%20des%20donnees%20Okali%20x%20Propulse_VF-n.pdf',
  brandPrivacyPolicyLink: 'https://info.propulsebyca.fr/caisses-regionales',
  appStoreLink: 'https://apps.apple.com/us/app/propulse-by-ca/id1623586276',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=com.creditagricole.pro',
  faq: faqDomainUrl,
  mandateSepa: `${faqDomainUrl}/hc/fr/articles/6267593411101`,
  invoicingFaq: `${faqDomainUrl}/hc/fr/sections/5723303440029`,
  requestHelp: `${faqDomainUrl}/hc/fr/requests/new`,
  rcProInfo: '',
  decadeGuaranteeInfo: '',
  multiriskInfo: '',
  legalPlace:
    'https://propulse.legalplace.fr/contrats/micro-entreprise-propulse/creer/1',
  helpNonDiffusible: `${faqDomainUrl}/hc/fr/articles/6203856149533`,
  helpNonEligible: `${faqDomainUrl}/hc/fr/articles/6203503940509`,
  helpBeneficialOwners: `${faqDomainUrl}/hc/fr/articles/6653098086557`,
  urssaf: {
    portal: 'http://autoentrepreneur.urssaf.fr/',
    microEntrepreneurs:
      'https://www.autoentrepreneur.urssaf.fr/portail/accueil/sinformer-sur-le-statut/lessentiel-du-statut.html',
    socialContributions: 'http://www.aquoiserventlescotisations.urssaf.fr/',
    sepaMandate: `${faqDomainUrl}/hc/fr/articles/6267593411101`,
    faq: '',
    tdaeStatusHelp: '',
    tdaePaymentAuthorization: '',
    cgu:
      'https://assets.website-files.com/62bd73fb692211e3172975e4/633aed6e43278997c780d425_CR%20-%20CGU%20Tierce%20De%CC%81claration%20Client%20final%20x%20URSSAF_VF%20(1).pdf',
  },
  faqWhoIsBlankFor: `${faqDomainUrl}/hc/fr/articles/6203572338333`,
  psdFAQ: '',
  legalLink: 'https://info.propulsebyca.fr',
  supportEmail: 'support@app.propulsebyca.fr',
  sepaTransfer: `${faqDomainUrl}/hc/fr/articles/6267593411101`,
  compareOffers: 'https://propulsebyca.fr/tarifs',
  cguBankAccount:
    'https://assets.website-files.com/62bd73fb692211e3172975e4/633aed5f1d4d477c75f4d99d_CR%20-%20Contrat%20cadre%20de%20services%20de%20paiement%20Okali%20x%20client%20final_VF%20(1).pdf',
  kycHelp: `${faqDomainUrl}/hc/fr/articles/6987070688157`,
}
