import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUser.query'
import { useEffect } from 'react'
import { setSentryUser } from '../lib/sentry/user'

export const useSetSentryUser = () => {
  const { data: user } = useAuthenticatedUserQuery()

  useEffect(() => {
    if (user) {
      setSentryUser(user)
    }
  }, [user])
}
