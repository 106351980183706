import { OfferService } from 'modules/corporate/types/offers'

export enum EnumBrand {
  BLANK = 'blank',
  LCL = 'lcl',
  CA = 'ca',
}

export type ConfigType = {
  internalBankName: string
  services: OfferService[]
  hasSubBanks: boolean
  isScaEnabledForBrand: boolean
  signupConfig: {
    shouldDisplayCommercialSolicitationOptIn: boolean
    shouldDisplayOperatedBy: boolean
    canAccessManagementOffer: boolean
  }
}
