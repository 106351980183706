import { DEFAULT_SCOPE } from 'modules/auth/types/auth0'
import { getScaLoginAction } from '../sca'

interface BuildScopeProps {
  withScaReset?: boolean
}

// For login flow, we provide SCA login action id only only if it has been validated
export const buildLoginScope = () => {
  const scaLoginAction = getScaLoginAction()

  let scope = DEFAULT_SCOPE

  if (scaLoginAction && scaLoginAction.isValidated) {
    scope += ` actionId:${scaLoginAction.id}`
  }

  return scope
}

// For refresh token flow, we provide SCA login action id as soon as it has been created by the user
export const buildRefreshTokenScope = ({
  withScaReset,
}: BuildScopeProps = {}) => {
  const scaLoginAction = getScaLoginAction()

  let scope = DEFAULT_SCOPE

  if (!withScaReset && scaLoginAction && scaLoginAction.id) {
    scope += ` actionId:${scaLoginAction.id}`
  }

  return scope
}
