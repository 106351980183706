interface BankingLimit {
  immutableRule: boolean
  ruleType: string
  parametersRule: LimitParametersRule
}

export interface PatchCardLimitsPayload extends CardLimit {
  cardId: string
}

export interface LimitParametersRule {
  value: number | boolean
  maximum?: number
  remaining?: number
}

export interface LimitsRule {
  dailyLimit: LimitParametersRule
  monthlyLimit: LimitParametersRule
}

export interface AccountLimit extends BankingLimit {
  resourceId: AccountLimitResourceId
}

export interface CardLimit extends BankingLimit {
  resourceId: CardLimitResourceId
}

export enum CardLimitResourceId {
  CONTACTLESS = 'contactLess',
  INTERNATIONAL = 'international',
  ONLINE = 'online',
  ATM = 'atm',
  LIMIT_ATM = 'limitATM',
  LIMIT_ATM_DAILY = 'limitATMDaily',
  LIMIT_GLOBAL = 'limitGlobal',
  LIMIT_ONLINE = 'limitOnline',
  LIMIT_GLOBAL_DAILY = 'limitGlobalDaily',
}

export enum AccountLimitResourceId {
  DAILY = 'SEPA Pomok outbound transfer daily',
  MONTHLY = 'SEPA Pomok outbound transfer monthly',
}

export interface FetchAccountLimitsResponse {
  data: AccountLimit[]
}

export interface FetchAccountCardLimitsResponse {
  data: CardLimit[]
}

export enum CardStatusCode {
  PREACTIVATED = 'PRE',
  BLOCKED = 'BLO',
  LOST = 'LOS',
  ACTIVE = 'ACT',
  CANCELLED = 'CAN',
}

export interface Card {
  resourceId: CardLimitResourceId
  cardDesign: string
  cardName: string
  complementName: string
  statusCode: CardStatusCode
  visibleNumber: string
  expiryDate: string
  cardType: string
  limitsGroup: string
  cardHolder: any
}

export interface FetchAccountCardsResponse {
  data: Card[]
}

export enum CardType {
  BLACKCARD = 'VISA_BUSINESS_DEBIT_NOIRE',
  WHITECARD = 'VISA_BUSINESS_DEBIT_BLANCHE',
}

export enum CardStatusPriority {
  ACT = 1,
  BLO,
  PRE,
  CAN,
  LOS,
}
