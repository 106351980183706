import { useRedirectAuthenticatedUser } from 'modules/auth/hooks/useRedirectAuthenticatedUser'
import { PropsWithChildren, useEffect } from 'react'
import { FullPageLoader } from '../FullPageLoader/FullPageLoader'

export const RedirectAuthenticatedUserWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    redirectAuthenticatedUser,
    isRedirectNeeded,
  } = useRedirectAuthenticatedUser()

  useEffect(() => {
    if (isRedirectNeeded) {
      redirectAuthenticatedUser()
    }
  }, [redirectAuthenticatedUser, isRedirectNeeded])

  if (isRedirectNeeded) {
    return <FullPageLoader />
  }

  return <>{children}</>
}
