import localStorage from 'modules/common/lib/storage/localStorage'
import { LocalStorageKeys } from '_constants'
import { ScaLoginAction } from './types'

export const DEFAULT_TIMER_DURATION = 300

export const getScaActionTimerDuration = (
  actionCreatedAt: string | undefined
) => {
  let elapsedTime = 0
  if (actionCreatedAt) {
    elapsedTime = (Date.now() - new Date(actionCreatedAt).getTime()) / 1000
  }
  return DEFAULT_TIMER_DURATION - elapsedTime
}

export const setScaLoginAction = (scaLoginAction: ScaLoginAction) => {
  localStorage.store(LocalStorageKeys.SCA_LOGIN_ACTION, scaLoginAction)
}

export const getScaLoginAction = () => {
  return localStorage.retrieve<ScaLoginAction | undefined>(
    LocalStorageKeys.SCA_LOGIN_ACTION
  )
}
