import Axios from 'axios'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { Corporate } from 'modules/corporate/types/corporates'
import { UseQueryOptions } from 'react-query'
import { EnumCorporateQueryKeys } from './keys'

interface FetchCorporateArgs {
  corporateId: string
  accessToken: string
}

const fetchCorporate = async ({
  corporateId,
  accessToken,
}: FetchCorporateArgs) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/v1/users/corporates/${corporateId}`

  const { data } = await Axios.get<Corporate>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return data
}

export const useCorporateRecordQuery = (
  corporateId?: string,
  queryOptions?: UseQueryOptions<Corporate, Error>
) => {
  return useQueryWithAuth<Corporate, Error>(
    [EnumCorporateQueryKeys.CORPORATES_RECORD, corporateId],
    async (context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporateRecordQuery: Missing parameter corporateId'
        )
      }
      return fetchCorporate({ corporateId, accessToken })
    },
    {
      ...queryOptions,
      enabled: !!corporateId,
    }
  )
}
