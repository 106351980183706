import { UserTrackingProperties } from 'modules/common/types/tracking'
import { TRACKING_EVENTS } from '../amplitude/events'

export const pushEvent = (event: TRACKING_EVENTS) => {
  window.dataLayer?.push({
    event: 'trackEvent',
    eventName: event,
  })
}

export const pushGTMProperties = (
  properties: Partial<UserTrackingProperties>
) => window.dataLayer?.push(properties)

export const resetGTMProperties = () => {
  const properties: UserTrackingProperties = {
    corporateId: null,
    affilae_click_id: null,
    awin_click_id: null,
    gclid: null,
    activityCode: null,
    activityLabel: null,
    isPhysicalPerson: null,
    legalFormCode: null,
    createdAt: null,
    status: null,
    auth0Id: null,
  }
  window.dataLayer?.push(properties)
}
