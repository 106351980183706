import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react'
import {
  SidebarContextReducerAction,
  useSidebarContextState,
} from './SidebarContext'

interface SidebarActions {
  selectMenu: (menuId: string) => void
  selectSubMenu: (subMenuId: string | undefined) => void
  openSidebar: () => void
  closeSidebar: () => void
  toggleSidebar: () => void
  selectFirstSubMenu: (menuId: string) => void
}

const SidebarActionsContext = createContext<SidebarActions | undefined>(
  undefined
)

interface Props {
  dispatch: React.Dispatch<SidebarContextReducerAction>
}

const SidebarActionsContextProvider: React.FC<PropsWithChildren<Props>> = ({
  dispatch,
  children,
}) => {
  const {
    menus,
    selectedMenuId,
    selectedSubMenuId,
    isSidebarOpen,
  } = useSidebarContextState()

  const selectMenu = useCallback(
    (menuId: string) => {
      if (selectedMenuId !== menuId) {
        dispatch({
          type: 'set',
          payload: { selectedMenuId: menuId },
        })
      }
    },
    [dispatch, selectedMenuId]
  )

  const selectSubMenu = useCallback(
    (subMenuId: string | undefined) => {
      if (selectedSubMenuId !== subMenuId) {
        dispatch({
          type: 'set',
          payload: {
            selectedSubMenuId: subMenuId,
          },
        })
      }
    },
    [dispatch, selectedSubMenuId]
  )

  const openSidebar = useCallback(
    () =>
      dispatch({
        type: 'set',
        payload: {
          isSidebarOpen: true,
        },
      }),
    [dispatch]
  )

  const closeSidebar = useCallback(
    () =>
      dispatch({
        type: 'set',
        payload: {
          isSidebarOpen: false,
        },
      }),
    [dispatch]
  )

  const toggleSidebar = useCallback(
    () =>
      dispatch({
        type: 'set',
        payload: {
          isSidebarOpen: !isSidebarOpen,
        },
      }),
    [dispatch, isSidebarOpen]
  )

  const selectFirstSubMenu = useCallback(
    (menuId) => {
      const firstSubMenu = menus.find(({ id }) => id === menuId)?.subMenus?.[0]

      selectSubMenu(firstSubMenu?.id)
    },
    [menus, selectSubMenu]
  )

  return (
    <SidebarActionsContext.Provider
      value={{
        selectMenu,
        selectSubMenu,
        openSidebar,
        closeSidebar,
        toggleSidebar,
        selectFirstSubMenu,
      }}
    >
      {children}
    </SidebarActionsContext.Provider>
  )
}

const useSidebarActionsContext = () => {
  const context = useContext(SidebarActionsContext)
  if (context === undefined) {
    throw new Error(
      'useSidebarActionsContext must be used within a SidebarActionsContextProvider'
    )
  }
  return context
}

export { SidebarActionsContextProvider, useSidebarActionsContext }
