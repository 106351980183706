import { useTranslation } from 'modules/common/hooks/useTranslation'
import { externalLinkProps } from 'modules/common/lib/helpers/externalLinkProps'
import { CopyText } from '../CopyText/CopyText'
import { Link } from '../Link/Link'
import { IconEmailRead, IconPhone } from '../_icons/icons'
import { Button } from '../_ui/Button/Button'

interface Props {
  faqURL?: string
  emailSupport?: string
  phoneSupport?: string
}

export const SupportModalContent: React.FC<Props> = ({
  faqURL,
  emailSupport,
  phoneSupport,
}) => {
  const { t } = useTranslation('common-components')
  const isSupportAvailable = !!emailSupport || !!phoneSupport

  return (
    <div className="max-w-sm self-center text-left">
      <h3 className="font-mukta py-8 text-center text-2xl font-bold">
        {t('supportModal.title')}
      </h3>
      {faqURL && (
        <>
          <h5 className="pb-2 text-sm font-semibold uppercase text-info-400">
            {t('supportModal.faqTitle')}
          </h5>
          <p className="pb-4">{t('supportModal.faqText')}</p>

          <Button
            variant="contained"
            color="active"
            className="mb-8 w-full min-w-full sm:min-w-0 sm:px-24"
            href={faqURL}
            {...externalLinkProps}
          >
            {t('supportModal.button')}
          </Button>
        </>
      )}
      {isSupportAvailable && (
        <>
          <h5 className="pb-2 text-sm font-semibold uppercase text-info-400">
            {t('supportModal.helpTitle')}
          </h5>
          <p className="pb-8">{t('supportModal.helpText')}</p>

          <div className="divide-y divide-info-200 rounded-2xl border border-info-200 px-5">
            {!!phoneSupport && (
              <div className="flex h-20 flex-row items-center py-3">
                <IconPhone className="rounded-lg bg-primary-90 text-primary" />
                <div className="flex flex-col pl-5">
                  <p className="font-headings text-sm font-bold">
                    {phoneSupport}
                  </p>
                  <p className="text-sm text-info-400">
                    {t('supportModal.phoneDetails')}
                  </p>
                </div>
              </div>
            )}

            {!!emailSupport && (
              <div className="flex h-20 flex-row items-center py-3">
                <IconEmailRead className="rounded-lg bg-primary-90 text-primary" />
                <Link
                  href={`mailto:${emailSupport}`}
                  className="pl-5 pr-6 font-headings text-sm font-bold"
                >
                  {emailSupport}
                </Link>
                <CopyText textToCopy={emailSupport} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
