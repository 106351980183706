import clsx from 'clsx'
import { useMemo } from 'react'
import { ButtonColor, ButtonVariant } from './Button'
import { BUTTON_THEME } from './Button.theme'

interface Props {
  variant: ButtonVariant
  color: ButtonColor | undefined
  disabled?: boolean
  isLoading?: boolean
}

export const useButtonTheme = ({
  variant,
  color = 'none',
  disabled,
  isLoading,
}: Props) => {
  const buttonClasses = useMemo(() => {
    return clsx(
      BUTTON_THEME[variant].base,
      !!color && {
        [BUTTON_THEME[variant][color]['default']]: !disabled,
        [BUTTON_THEME[variant][color]['loading'] || '']: isLoading,
        [BUTTON_THEME[variant][color]['disabled']]: disabled && !isLoading,
      }
    )
  }, [variant, color, disabled, isLoading])

  return { buttonClasses }
}
