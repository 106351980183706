import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import { Service } from 'axios-middleware'

// https://emileber.github.io/axios-middleware/#/
const service = new Service(axios)

service.register({
  onResponseError(error: any) {
    if (error.response?.config) {
      Sentry.captureMessage(
        `[API/${error.response.status}] - ${error.response.config.method}/${error.response.config.url}`,
        { level: Sentry.Severity.Error }
      )
    }
    throw error
  },
})
