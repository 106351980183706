import { TokenResponse } from 'auth0'
import jwtDecode from 'jwt-decode'
import { AuthenticatedUser, TokenUser } from 'modules/auth/types/auth0'
import cookieStorage from 'modules/common/lib/storage/cookieStorage'
import { CookieKeys } from '_constants'
import { serialize } from 'cookie'

export const getAccessToken = () => {
  return cookieStorage.retrieve(CookieKeys.AUTH_ACCESS_TOKEN)
}

export const clearAccessToken = () => {
  cookieStorage.clear(CookieKeys.AUTH_ACCESS_TOKEN)
}

export const assertAccessTokenIsNotExpired = (token: TokenUser) => {
  if (!token.exp) {
    return false
  }
  const currentTime = Date.now() / 1000
  return currentTime <= token.exp
}

export const isAccessTokenExpired = (accessToken: string) => {
  if (!accessToken) {
    return true
  }
  const jwtToken = jwtDecode<TokenUser>(accessToken)
  return !assertAccessTokenIsNotExpired(jwtToken)
}

export const getScopeInAccessToken = (accessToken: string) => {
  const token = jwtDecode<TokenUser>(accessToken)
  return token.scope
}

export const getAuthenticatedUser = (
  token: string
): AuthenticatedUser | null => {
  const tokenUser = jwtDecode<TokenUser>(token)
  if (!tokenUser) {
    return null
  }

  return {
    firstname: tokenUser?.['https://blank.app/firstname'],
    lastname: tokenUser?.['https://blank.app/lastname'],
    corporateId: tokenUser?.['https://blank.app/corporateId'],
    email: tokenUser?.['https://blank.app/email'],
    emailVerified: tokenUser?.['https://blank.app/email_verified'],
    sub: tokenUser?.sub,
    roles: tokenUser?.['https://blank.app/roles'],
    phoneNumber: tokenUser?.['https://blank.app/phoneNumber'],
    phoneNumberPending: tokenUser?.['https://blank.app/phoneNumberPending'],
    phoneNumberVerified: tokenUser?.['https://blank.app/phoneNumberVerified'],
    hasMFA: tokenUser?.['https://blank.app/hasMFA'],
    hasCompletedSca: tokenUser?.['https://blank.app/hasCompletedSCA'], // has a level 2 token (auth verified by SCA)
    isEnrolled: tokenUser?.['https://blank.app/isEnrolled'], // has enrolled a SCA device
    actionId: tokenUser?.['https://blank.app/actionId'], // sca action id
  }
}

export const buildAuthCookies = (token: TokenResponse) => {
  return [
    serialize(CookieKeys.AUTH_ACCESS_TOKEN, token.access_token, {
      path: '/',
      secure: true,
    }),
    ...(token.refresh_token
      ? [
          serialize(CookieKeys.AUTH_REFRESH_TOKEN, token.refresh_token, {
            path: '/',
            secure: true,
            httpOnly: true,
          }),
        ]
      : []),
  ]
}

export const clearAuthCookies = () => {
  return [
    serialize(CookieKeys.AUTH_ACCESS_TOKEN, '', {
      maxAge: -1,
      secure: true,
      path: '/',
    }),
    serialize(CookieKeys.AUTH_REFRESH_TOKEN, '', {
      maxAge: -1,
      secure: true,
      path: '/',
    }),
  ]
}
