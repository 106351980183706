import amplitude from 'amplitude-js'
import { UserTrackingProperties } from 'modules/common/types/tracking'
import { TRACKING_EVENTS } from './events'

const AMPLITUDE_ID = process.env.NEXT_PUBLIC_AMPLITUDE_ID || ''

export const initAmplitude = () => {
  amplitude.getInstance().init(AMPLITUDE_ID, undefined, {
    includeUtm: true,
  })
}

export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitude.getInstance().setDeviceId(installationToken)
}

export const setAmplitudeUserProperties = (
  properties: Partial<UserTrackingProperties>
) => {
  amplitude.getInstance().setUserId(properties.corporateId || null)
  amplitude.getInstance().setUserProperties(properties)
}

export const resetAmplitudeUserProperties = () => {
  amplitude.getInstance().setUserId(null)
  amplitude.getInstance().setUserProperties(null)
}

export const sendAmplitudeData = (
  eventType: TRACKING_EVENTS,
  eventProperties?: any
) => {
  amplitude
    .getInstance()
    .logEvent(eventType, { eventSource: 'FRONT_WEB', ...eventProperties })
}
