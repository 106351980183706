import clsx from 'clsx'
import React, {
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  useMemo,
} from 'react'
import { Link } from '../../Link/Link'
import { Spinner } from '../Spinner/Spinner'
import { useButtonTheme } from './useButtonTheme'
import { useSpinnerTheme } from './useSpinnerTheme'

export const BUTTON_VARIANTS = [
  'contained',
  'outline',
  'link',
  'icon',
  'none',
] as const
export const BUTTON_COLORS = ['none', 'active', 'warning', 'danger'] as const

export type ButtonVariant = typeof BUTTON_VARIANTS[number]
export type ButtonColor = typeof BUTTON_COLORS[number]

export type ButtonDesignOptionProps = {
  variant: ButtonVariant
  color?: ButtonColor
}

export type ButtonBaseProps = {
  isLoading?: boolean
  disabled?: boolean
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
} & (
  | (React.ComponentProps<'button'> & {
      href?: never
      target?: never
      type?: string
      rel?: never
    })
  | (React.ComponentProps<'a'> & {
      href: string
      target?: string
      type?: never
      rel?: string
    })
) & {
    onClick?: (
      event:
        | MouseEvent<HTMLButtonElement, MouseEvent>
        | MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => void
  }

export type ButtonProps = ButtonBaseProps & ButtonDesignOptionProps

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      color,
      children,
      disabled,
      href,
      icon,
      iconPosition = 'left',
      isLoading,
      variant,
      ...buttonProps
    }: ButtonProps,
    ref
  ) => {
    const { buttonClasses } = useButtonTheme({
      color,
      variant,
      disabled,
      isLoading,
    })

    const { isSpinnerOnTop, spinnerClasses } = useSpinnerTheme({
      color,
      variant,
    })

    const iconContent = useMemo(() => {
      if (!isSpinnerOnTop && isLoading) {
        return <Spinner {...spinnerClasses} />
      }
      return (
        <>
          {isLoading && <Spinner {...spinnerClasses} />}
          {icon}
        </>
      )
    }, [icon, isLoading, isSpinnerOnTop, spinnerClasses])

    const AsElement = href && !disabled ? Link : 'button'

    return (
      <AsElement
        disabled={disabled}
        {...(!disabled ? { href } : null)}
        // Button and Anchor props keep clashing, any was the only workaround I found
        {...(buttonProps as any)}
        className={clsx(buttonClasses, buttonProps.className)}
        ref={ref}
      >
        <span
          className={clsx(
            'flex flex-row items-center justify-center gap-2',
            isLoading && isSpinnerOnTop && 'bg-transparent text-transparent'
          )}
        >
          {iconPosition === 'left' && iconContent}
          {variant !== 'icon' && children}
          {iconPosition === 'right' && iconContent}
        </span>
      </AsElement>
    )
  }
)
