import { useFreshToken } from 'modules/auth/hooks/useFreshToken'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import { QueryFunction } from '../types/queries'

export const useQueryWithAuth = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  queryOptions?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  const getFreshToken = useFreshToken()

  return useQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    async (context) => {
      const accessToken = await getFreshToken()

      if (!accessToken) {
        throw new Error(`useQueryWithAuth[${queryKey}]: Missing Access Token`)
      }

      return queryFn(context, accessToken)
    },
    queryOptions
  )
}
