import { getIsKycComplete } from 'modules/auth/lib/utils/getIsKycComplete'
import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporate.query'
import { useCallback, useMemo } from 'react'
import { useCorporateLifecycleQuery } from '../queries/useCorporateLifecycle.query'

export const useCorporateLifecycleQueries = () => {
  const {
    data: { corporate, user },
    isLoading: isCorporateLoading,
    isError: isCorporateError,
    isSuccess: isCorporateSuccess,
    errorRefetch: refetchCorporate,
  } = useAuthenticatedUserCorporateQuery()

  const {
    data: lifecycle,
    isLoading: isLifecycleLoading,
    isError: isLifecycleError,
    isSuccess: isLifecycleSuccess,
    refetch: refetchLifecycle,
  } = useCorporateLifecycleQuery(corporate?.id)

  const errorRefetch = useCallback(() => {
    if (isCorporateError) {
      return refetchCorporate
    } else if (isLifecycleError) {
      return refetchLifecycle
    }
  }, [isCorporateError, isLifecycleError, refetchCorporate, refetchLifecycle])

  const isKycComplete = useMemo(() => getIsKycComplete(lifecycle), [lifecycle])

  return {
    data: {
      corporate,
      isKycComplete,
      lifecycle,
      user,
    },
    isLoading: isCorporateLoading || isLifecycleLoading,
    isError: isCorporateError || isLifecycleError,
    isSuccess: isCorporateSuccess && isLifecycleSuccess,
    errorRefetch,
  }
}
