import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
} from 'react'

interface LoginContext {
  user: {
    email: string
  }
}

const defaultContext: LoginContext = {
  user: {
    email: '',
  },
}

type Action = {
  type: 'setUser'
  payload: Partial<LoginContext['user']>
}

type Dispatch = (action: Action, asyncFn?: () => Promise<any>) => Promise<any>

const LoginStateContext = createContext<LoginContext>(defaultContext)
const LoginDispatchContext = createContext<Dispatch | undefined>(undefined)

function loginContextReducer(
  state: LoginContext,
  action: Action
): LoginContext {
  switch (action.type) {
    case 'setUser': {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      }
    }
  }
}
function LoginContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(loginContextReducer, defaultContext)

  const dispatchAsync = useCallback(
    async (action: Action, asyncFn?: () => Promise<any>) => {
      dispatch(action)
      return asyncFn && asyncFn()
    },
    []
  )

  return (
    <LoginStateContext.Provider value={state}>
      <LoginDispatchContext.Provider value={dispatchAsync}>
        {children}
      </LoginDispatchContext.Provider>
    </LoginStateContext.Provider>
  )
}

function useLoginContextState() {
  const context = useContext(LoginStateContext)
  if (context === undefined) {
    throw new Error(
      'useLoginContextState must be used within a LoginContextProvider'
    )
  }
  return context
}
function useLoginContextDispatch() {
  const context = useContext(LoginDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useLoginContextDispatch must be used within a LoginContextProvider'
    )
  }
  return context
}

export { LoginContextProvider, useLoginContextState, useLoginContextDispatch }
