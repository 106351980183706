import { EnumBrand } from 'config/types'
import { BLANK_EXTERNAL_LINKS } from './blank'
import { CA_EXTERNAL_LINKS } from './ca'
import { LCL_EXTERNAL_LINKS } from './lcl'
import { ExternalLinks } from './type'

const externalLinksByBrand: Record<EnumBrand, ExternalLinks> = {
  blank: BLANK_EXTERNAL_LINKS,
  ca: CA_EXTERNAL_LINKS,
  lcl: LCL_EXTERNAL_LINKS,
}

const buildTimeBrand: EnumBrand =
  (process.env.NEXT_PUBLIC_BRAND as EnumBrand) ||
  (process.env.STORYBOOK_BRAND as EnumBrand) ||
  EnumBrand.BLANK

export const externalLinks: ExternalLinks = externalLinksByBrand[buildTimeBrand]

export const LEGAL_PLACE_SERVICE_PRICE = 59

export enum LocalStorageKeys {
  INVOICE_CREATION_FORM = 'blnk-invoice-creation-form',
  ESTIMATE_CREATION_FORM = 'blnk-estimate-creation-form',
  SIGNUP_FORM = 'blnk-signup-form',
  SCA_LOGIN_ACTION = 'sca-login-action',
  BANNERS_TO_HIDE = 'blnk-banners-to-hide',
}

export enum CookieKeys {
  LANDING_PAGE_PARAMETERS = 'blnk_landing_page_parameters',
  AUTH_ACCESS_TOKEN = 'blnk-auth0-access-token',
  AUTH_REFRESH_TOKEN = 'blnk-auth0-refresh-token',
}

export enum MimeTypes {
  PDF = 'application/pdf',
  CSV = 'text/csv',
}

export enum FileSizeInBytes {
  '5MB' = 5242880,
  '10MB' = 10485760,
}
