import { ExternalLinks } from './type'

const faqDomainUrl = process.env.NEXT_PUBLIC_FAQ_DOMAIN || ''

export const BLANK_EXTERNAL_LINKS: ExternalLinks = {
  cguLink: 'https://www.blank.app/documents-legaux/cgu-Plateforme-de-Gestion',
  bankingOrganizationPrivacyPolicyLink:
    'https://www.blank.app/documents-legaux/politique-protection-des-donnees',
  brandPrivacyPolicyLink: '',
  appStoreLink:
    'https://apps.apple.com/fr/app/blank-lapp-des-ind%C3%A9pendants/id1522046776',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=app.blank.mobile&hl=fr',
  faq: faqDomainUrl,
  mandateSepa: `${faqDomainUrl}/hc/fr/articles/4433238933905`,
  invoicingFaq: `${faqDomainUrl}/hc/fr/sections/6018171570193`,
  requestHelp: `${faqDomainUrl}/hc/fr/requests/new`,
  rcProInfo:
    'https://www.blank.app/assurance-professionnelle/responsabilite-civile-professionnelle',
  decadeGuaranteeInfo:
    'https://www.blank.app/assurance-professionnelle/assurance-decennale',
  multiriskInfo:
    'https://www.blank.app/assurance-professionnelle/assurance-multirisque-professionnelle',
  legalPlace:
    'https://www.legalplace.fr/contrats/micro-entreprise-blank/creer/1',
  helpNonDiffusible: `${faqDomainUrl}/hc/fr/articles/4433239752849`,
  helpNonEligible: `${faqDomainUrl}/hc/fr/articles/4433239644177`,
  helpBeneficialOwners: `${faqDomainUrl}/hc/fr/articles/4433243620369`,
  urssaf: {
    portal: 'http://autoentrepreneur.urssaf.fr/',
    microEntrepreneurs:
      'https://www.autoentrepreneur.urssaf.fr/portail/accueil/sinformer-sur-le-statut/lessentiel-du-statut.html',
    socialContributions: 'http://www.aquoiserventlescotisations.urssaf.fr/',
    sepaMandate: 'https://www.blank.app/compte-professionnel/mandat-sepa',
    faq: `${faqDomainUrl}/hc/fr/sections/6018170469137`,
    tdaeStatusHelp: `${faqDomainUrl}/hc/fr/articles/4433228060049`,
    tdaePaymentAuthorization: `${faqDomainUrl}/hc/fr/articles/4433237466257`,
    cgu: 'https://www.blank.app/documents-legaux/cgu-tierce-declaration-urssaf',
  },
  faqWhoIsBlankFor: `${faqDomainUrl}/hc/fr/articles/6835891456785`,
  psdFAQ: `${faqDomainUrl}/hc/fr/articles/8095900392721`,
  legalLink: 'https://www.blank.app/documents-legaux',
  supportEmail: 'support@blank.app',
  sepaTransfer: `${faqDomainUrl}/hc/fr/articles/5846985316753`,
  compareOffers: 'https://www.blank.app/tarifs',
  cguBankAccount:
    'https://www.blank.app/documents-legaux/cgu-Plateforme-de-Gestion',
  kycHelp: `${faqDomainUrl}/hc/fr/articles/7658164435985`,
}
